import {useActions} from "../../../hooks/useActions";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {ICampaign} from "../types/sponsoredProducts";
import {IPlacementData} from "../types/placement";
import {placementsEnum} from "../types/types";
import {copy} from "../../../helper/system.helpers.functions";

interface HandleChildBidAdjustmentParams {
    placement: IPlacementData[],
    keywordBid: number,
}

export const useExpandTableHelperFunctions = () => {
    const { setSelectedCampaignIds, updateCampaign } = useActions()

    const { selectedCampaignIds } = useTypedSelector((select) => select.displaySettingsSp)
    const { campaignData } = useTypedSelector((select) => select.sponsoredProduct)

    const HandleChildBidAdjustment = (params: HandleChildBidAdjustmentParams): ICampaign | undefined => {
        const {
            placement,
            keywordBid,
        } = params

        const currentPlacement: IPlacementData[] = copy(placement)
        let campaign = campaignData.campaigns.find((campaign) =>
            campaign.campaignId === currentPlacement[0].campaignId
        )
        if (!campaign) {
            return
        }

        let resule = 0
        if (currentPlacement[0]?.targetAcos != undefined && currentPlacement[1]?.targetAcos != undefined && currentPlacement[2]?.targetAcos != undefined) {
            let toptargetCPC = Number(currentPlacement[0]?.limitedCPC) || 0;
            let rostargetCPC = Number(currentPlacement[1]?.limitedCPC) || 0;
            let pptargetCPC = Number(currentPlacement[2]?.limitedCPC) || 0;

            let getLowestValue = Math.min(toptargetCPC, pptargetCPC, rostargetCPC)
            resule = getLowestValue;

            if (resule == 0) {
                resule = keywordBid
            }

            if (currentPlacement[0].orders == 0 && currentPlacement[0].clicks < 4) {
                let val = Number(getLowestValue) * (Number(campaignData.TOSPercentage) / 100);
                let sum = Number((Number(getLowestValue) + Number(val)).toFixed(2))
                currentPlacement[0].limitedCPC = Number(isNaN(sum) ? 0 : sum < 0 ? 0 : sum > 900 ? 900 : sum)
            }
            let firstLimitedcpcRow = Number(currentPlacement[0].limitedCPC);
            let secondLimitedcpcRow = Number(currentPlacement[1].limitedCPC);
            let thirdLimitedcpcRow = Number(currentPlacement[2].limitedCPC);

            let FIRSTDiffrence = Number(firstLimitedcpcRow) / Number(resule);
            let firstVal = Number(((FIRSTDiffrence - 1) * 100).toFixed(0))
            currentPlacement[0].newbidAdjustment = Number(isNaN(firstVal) ? 0 : firstVal < 0 ? 0 : firstVal > 900 ? 900 : firstVal)
            let SecondDiffrence = Number(secondLimitedcpcRow) / Number(resule);
            let secondVal = Number(((SecondDiffrence - 1) * 100).toFixed(0))
            currentPlacement[1].newbidAdjustment = Number( isNaN(secondVal) ? 0 : secondVal < 0 ? 0 : secondVal > 900 ? 900 : secondVal)

            let thirdDiffrence = Number(thirdLimitedcpcRow) / Number(resule);
            let thirdVal = Number(((thirdDiffrence - 1) * 100).toFixed(0))
            currentPlacement[2].newbidAdjustment = Number(isNaN(thirdVal) ? 0 : thirdVal < 0 ? 0 : thirdVal > 900 ? 900 : thirdVal)

            campaign = getCampaignWithUpdateBid(campaign, resule)
            campaign.placementsData = currentPlacement
            changeSelectedCampaignIds(campaign, resule)
        }
        return campaign
    }

    const getCampaignWithUpdateBid = (campaign, val): ICampaign => {
        const campaignWithUpdate = campaign
        if (campaignWithUpdate) {
            campaignWithUpdate.newKeywordBid = Number(val)
            campaignWithUpdate.newbidAdjustment = Number(val)
            return campaignWithUpdate
        }
        else {
            throw Error('Campaign No Exists')
        }
    }

    function changeSelectedCampaignIds(campaign: ICampaign, resule) {
        const campaignHasNotChange = campaign.placementsData &&
            Number(campaign.keywordBid) === Number(resule) &&
            Number(campaign.placementsData[0]?.placementModifier) === Number(campaign.placementsData[0]?.newbidAdjustment) &&
            Number(campaign.placementsData[1]?.placementModifier) === Number(campaign.placementsData[1]?.newbidAdjustment) &&
            Number(campaign.placementsData[2]?.placementModifier) === Number(campaign.placementsData[2]?.newbidAdjustment)

        if (campaignHasNotChange) {
            setSelectedCampaignIds(selectedCampaignIds.filter(id => id !== campaign.campaignId))
        } else {
            setSelectedCampaignIds([...selectedCampaignIds, campaign.campaignId])
        }
    }

    const calLimitedCpcANDTargetCpcAmazon = (placement, keywordBid) => {
        let targetCpcAmazon;
        let queryResult = (Number(keywordBid) * Number(placement.placementModifier)) / 100;
        let finalResult = Number(queryResult) + Number(keywordBid);
        placement.tagetCPCAmazon = finalResult ? finalResult.toFixed(2) : 0;
        targetCpcAmazon = finalResult ? finalResult.toFixed(2) : 0;
        const limitValue = campaignData.limitedCPCValue.conditions
        let limitedCPCValue = 0;
        const M7 = Number(targetCpcAmazon);
        const N7 = Number(placement.targetCPC);
        const H3 = limitValue.find(x => placement.acos >= x.from && placement.acos <= x.to)?.increaseBid;
        const H4 = limitValue.find(x => placement.acos >= x.from && placement.acos <= x.to)?.decreaseBid;

        const calculateResult = (M7, N7, H3, H4) => {
            if (M7 <= N7) {
                return Math.min(M7 * (1 + H3 / 100), N7);
            } else {
                return Math.max(M7 * (1 - H4 / 100), N7);
            }
        }

        limitedCPCValue = calculateResult(M7, N7, H3, H4);
        placement.limitedCPC = limitedCPCValue

        let val = Number(limitedCPCValue) < campaignData.minTargetCPC ? campaignData.minTargetCPC : Number(limitedCPCValue) > campaignData.maxTargetCPC ? campaignData.maxTargetCPC : Number(limitedCPCValue)
        placement.limitedCPC = placement?.clicks <= 4 ? campaignData.defaultBid : val.toFixed(2)
    }

    const HandleTargetCpcCondition = (crrObj, rowIndex, keywordBid) => {
        if (rowIndex == placementsEnum.PRODUCT_PAGE) {
            let all = 0;
            let one = 0;
            let two = 0;
            let placementWithClicksAndSale: any = {}
            let placementWithoutClicksAndSale: any[] = []

            for (let i = 0; i < crrObj.length; i++) {

                if (crrObj[i].clicks < 4 && crrObj[i].orders == 0) {
                    all++;
                }
                if (crrObj[i].clicks > 0 && crrObj[i].orders > 0) {
                    one++;
                }
                else if (crrObj[i].clicks >= 4 && crrObj[i].orders == 0) {
                    one++
                }
            }
            if (one == 1) {
                for (let i = 0; i < crrObj.length; i++) {
                    if (crrObj[i].clicks > 0 && crrObj[i].orders > 0) {
                        placementWithClicksAndSale = crrObj[i]
                    }
                    else if (crrObj[i].clicks >= 4 && crrObj[i].orders == 0) {
                        placementWithClicksAndSale = crrObj[i]
                    }
                    if (crrObj[i].clicks < 4 && crrObj[i].orders == 0) {
                        two++;
                        placementWithoutClicksAndSale.push(crrObj[i])
                    }
                }
            }

            else if (one == 1 && two == 2) {
                placementWithoutClicksAndSale.map((el, i) => {
                    let index = crrObj?.findIndex(x => x == el)
                    crrObj[index].targetCPC = placementWithClicksAndSale.targetCPC
                })

            }
            else {
                crrObj.map((el, i) => {
                    if (crrObj[i].orders == 0 && crrObj[i].clicks < 4) {
                        let lowestCPC = Math.min(
                            crrObj[(i + 1) % crrObj.length].targetCPC,
                            crrObj[(i + 2) % crrObj.length].targetCPC
                        );
                        crrObj[i].targetCPC = lowestCPC
                    }

                })
            }
        }

        crrObj.map((el) => {
            calLimitedCpcANDTargetCpcAmazon(el, keywordBid)
        })
    }

    return {
        HandleChildBidAdjustment,
        calLimitedCpcANDTargetCpcAmazon,
        HandleTargetCpcCondition,
    }
}

