import React from "react";
import moment from "moment-timezone";
import {weekFilter, weekFilterEnum} from "../types/types";
import {ISubDataTableResult} from "../types/childDataTable";

interface ExpandTableRowsProps {
    weekData: ISubDataTableResult[],
    datePeriodFilter: weekFilter
}

const ExpandTableRows: React.FC<ExpandTableRowsProps> = ({ weekData, datePeriodFilter }) => {
    const widthTable = weekData.length > 12 ? "auto" : "fit-content"
    const isWeekFilterNoDay = datePeriodFilter === weekFilterEnum.WEEK || datePeriodFilter === weekFilterEnum.MONTH

    const getFromDate = (el) => {
        return moment(el.fromDate).format("DD-MM-YYYY")
    }

    const getToDate = (el) => {
        return moment(el.toDate).format("DD-MM-YYYY")
    }

    return (
        <tbody style={{width: widthTable}}>
            {weekData.map((el) =>
                <tr>
                    <td className="date_show">
                        {isWeekFilterNoDay ? (
                            <span>
                                {getFromDate(el)}
                            <br/>
                                {getToDate(el)}
                            </span>
                        ) : (
                            getToDate(el)
                        )}
                    </td>
                    <td>{el.organicPosition}</td>
                    <td>{el.sponsoredPosition || 0}</td>
                    <td>{el.sales}</td>
                    <td>{el.spend}</td>
                    <td>{el.profit}</td>
                    <td>{el.profitMargin}</td>
                    <td>{el.acos}</td>
                    <td>{el.orders}</td>
                    <td>{el.units}</td>
                    <td>{el.cvr}</td>
                    <td>{el.cpc}</td>
                    <td>{el.impressions}</td>
                    <td>{el.clicks}</td>
                    <td>{el.ctr}</td>
                    <td>{el.cpc}</td>
                    <td>{el.avgSalesPrice}</td>
                </tr>
            )}
        </tbody>
    )
};

export default ExpandTableRows;
