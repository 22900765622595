import {LoginForm} from "../modules/login";

const Login = () => {

    return <div className="login-page">
        <LoginForm/>
    </div>
}

export default Login
