import React, {useEffect, useRef, useState} from "react";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import VirtualizedTable from "./VirtualizedTable";
import DateTableSettings from "./DateTableSettings";
import ExpandTableRows from "./ExpandTableRows";
import ExpandTableData from "./ExpandTableData";
import {useExpandTableHelperFunctions} from "../hooks/useExpandTableHelperFunctions";
import {ISubDataTableResult} from "../types/childDataTable";
import {placementsEnum, weekFilter} from "../types/types";
import useExpandColumns from "../hooks/useExpandColumns";
import {IPlacementData} from "../types/placement";
import {copy} from "../../../helper/system.helpers.functions";
import {ICampaign} from "../types/sponsoredProducts";
import {useActions} from "../../../hooks/useActions";
import {dataRepresentationEnum} from "../../../types/customers";

interface ExpandTableProps {
	placementData: IPlacementData[],
	keywordBid: number,
	weekData: ISubDataTableResult[],
	campaignName: string,
	campaignId: string,
	order: number,
	datePeriodType: weekFilter,
	campaign: any
}

const ExpandTable: React.FC<ExpandTableProps> = ({
	placementData,
	keywordBid,
	weekData,
	campaignName,
	campaignId,
	order,
	datePeriodType,
	campaign
}) => {
	const { campaignData } = useTypedSelector((select) => select.sponsoredProduct)
	const { bestArr, dataRepresentationType } = useTypedSelector((select) => select.displaySettingsSp)
	const { HandleChildBidAdjustment, HandleTargetCpcCondition } = useExpandTableHelperFunctions()
	const { updateCampaign } = useActions()

	const columns = useExpandColumns({
		order,
		campaignName,
		campaignId,
		keywordBid,
		subData: placementData
	})

	useEffect(() => {
		weekData?.reverse()
	}, [weekData])

	const cal = (): ICampaign | undefined => {
		if (placementData?.length === 3) {
			let crrObj = copy(placementData)
			let Sales = 0
			let Units = 0

			for (let i = 0; i < crrObj?.length; i++) {
				Sales = Sales + crrObj[i].sales;
				Units = Units + crrObj[i].orders;
			}
			let AOV

			crrObj?.map((e, rowIndex) => {

				let eventValue = e.targetAcos;
				let TotalSales = e.sales;
				let TotalClick = e.clicks;

				let result;

				if (order == 0) {
					campaignData.getAOVData.forEach(element => {
						let uppercase = element.campaignName.toUpperCase()
						let name = new RegExp(uppercase, 'i')
						if (name.test(campaignName)) {
							AOV = element.value
						}
					});
				}
				else {
					AOV = Sales / Units
				}

				if (!AOV) {
					AOV = Sales / Units
				}

				if (crrObj[rowIndex].orders == 0 && crrObj[rowIndex].clicks >= 4) {
					result = (1 / (crrObj[rowIndex].clicks + 1)) * (AOV) * (eventValue / 100)
				}

				else if (crrObj[rowIndex].sales == 0 && crrObj[rowIndex].clicks < 4) {
					result = (Number(AOV) / (Number(crrObj[rowIndex].clicks) + 1)) * (eventValue / 100)
				}
				else {
					if (crrObj[rowIndex].orders <= 3 && crrObj[rowIndex].cvr >= 50) {
						result = crrObj[rowIndex].cpc + (crrObj[rowIndex].cpc * 0.5)
					}
					else {
						result = (TotalSales / TotalClick) * (eventValue / 100);
					}
				}
				let finalVal = result.toFixed(2)

				if (isNaN(finalVal)) {
					finalVal = 0
				}
				crrObj[rowIndex].targetAcos = !eventValue ? 0 : eventValue;

				if (bestArr.includes(e.campaignId)) {
					crrObj[rowIndex].targetCPC = crrObj[rowIndex].targetCVRCPC == 1 ? crrObj[rowIndex].targetCPC : crrObj[rowIndex].bestCVRCPC
				}

				crrObj[rowIndex].targetCPC = eventValue
					? Number(finalVal) < campaignData.minTargetCPC
						? campaignData.minTargetCPC
						: Number(finalVal) > campaignData.maxTargetCPC
							? campaignData.maxTargetCPC
							: Number(finalVal)
					: 0
				HandleTargetCpcCondition(crrObj, rowIndex, keywordBid)
			})

			crrObj[0].oldCPC = crrObj[0].targetCPC
			crrObj[1].oldCPC = crrObj[1].targetCPC
			crrObj[2].oldCPC = crrObj[2].targetCPC

			return HandleChildBidAdjustment({
				placement: crrObj,
				keywordBid,
			})
		}
	}

	useEffect(() => {
		let campaign = cal()
		if (campaign && campaign.placementsData && campaign.placementsData.length === 3) {
			campaign.placementsData = changeTargetAcosForPlacements(campaign.placementsData)
			updateCampaign(campaign)
		}
	}, [placementData.length])

	useEffect(() => {
		const prevCampaign = copy(campaign)
		return () => {
			if (prevCampaign) {
				updateCampaign(prevCampaign)
			}
		}
	}, []);

	useEffect(() => {
		const subDataCopy = placementData
		if (bestArr.includes(subDataCopy[0]?.campaignId)) {
			subDataCopy.forEach((crrObj) => {
				let bestCPC = crrObj.targetCVRCPC === 1 ? crrObj.targetCPC : crrObj.bestCVRCPC;
				crrObj.bestCPC = bestCPC
				crrObj.newbidAdjustment = crrObj.placementModifier
				if (crrObj.placement === placementsEnum.TOP_OF_SEARCH || crrObj.placement === placementsEnum.REST_OF_SEARCH) {
					crrObj.limitedCPC = bestCPC >= crrObj.targetCPC ? bestCPC : crrObj.targetCPC;
					crrObj.targetCPC = bestCPC >= crrObj.targetCPC ? bestCPC : crrObj.targetCPC;
				} else {
					crrObj.limitedCPC = bestCPC >= crrObj.targetCPC ? crrObj.targetCPC : bestCPC;
					crrObj.targetCPC = bestCPC >= crrObj.targetCPC ? crrObj.targetCPC : bestCPC;
				}
			})
		}
	}, [placementData, bestArr])

	const findAcos = (records, givenValue) => {
		for (let record of records) {
			if (record.from <= givenValue && givenValue <= record.to) {
				return record.Acos
			}
		}
		return 0;
	}

	function changeTargetAcosForPlacements(placement): IPlacementData[] {
		const placementLocal = copy(placement)

		if (placementLocal.length > 0) {
			let a = campaignName.split(/\s+/);

			const isWordIncludedInObject = (word, obj) => {
				return word.type.toLowerCase().includes(obj.toLowerCase());
			}
			let find = campaignData.defaultTargetAcos.find(word => a.some(obj => isWordIncludedInObject(word, obj)));
			if (find === undefined) {
				find = campaignData.defaultTargetAcos.find(x => x.type == "All other")
			}

			if (find?.dynamicTOSTarget == true) {
				placementLocal[0].targetAcos = findAcos(find.dynamicTOStargetAcos, placementLocal[0].cvr);
			} else {
				placementLocal[0].targetAcos = find?.TOStargetAcos || 0
			}

			if (find?.dynamicROSTarget == true) {
				placementLocal[1].targetAcos = findAcos(find.dynamicROStargetAcos, placementLocal[1].cvr);
			} else {
				placementLocal[1].targetAcos = find?.ROStargetAcos || 0
			}

			if (find?.dynamicPPTarget == true) {
				placementLocal[2].targetAcos = findAcos(find.dynamicPPtargetAcos, placementLocal[2].cvr);
			} else {
				placementLocal[2].targetAcos = find?.PPtargetAcos || 0
			}
		}
		return placementLocal
	}

	return (
		<>
			{dataRepresentationType === dataRepresentationEnum.PLACEMENT ? (
				<VirtualizedTable
					data={placementData}
					columns={columns}
				/>
			) : (
				<>
					<DateTableSettings
						campaignId={campaignId}
					/>
					<div className="weekly_table mb-5 expand_table">
						<table className="table table-striped">
							<ExpandTableRows
								datePeriodFilter={datePeriodType}
							/>

							<ExpandTableData
								weekData={weekData}
								datePeriodFilter={datePeriodType}
							/>
						</table>
					</div>
				</>
			)}
		</>
	);
}

export default ExpandTable
