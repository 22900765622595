import {Dispatch} from "redux";
import {
    DisplaySettingAction,
    DisplaySettingsActionType,
} from "../types/displaySettings";
import {sortType} from "../../types/types";
import {dataRepresentationEnum} from "../../../../types/customers";

export const setBestArr = (bestArr: number[]) => {
    return (dispatch: Dispatch<DisplaySettingAction>) => {
        dispatch({
            type: DisplaySettingsActionType.SET_BEST_ARR,
            payload: bestArr,
        })
    }
}

export const setFieldName = (fieldName: string) => {
    return (dispatch: Dispatch<DisplaySettingAction>) => {
        dispatch({
            type: DisplaySettingsActionType.SET_FIELD_NAME,
            payload: fieldName,
        })
    }
}

export const setSortType = (sortType: sortType) => {
    return (dispatch: Dispatch<DisplaySettingAction>) => {
        dispatch({
            type: DisplaySettingsActionType.SET_SORT_TYPE,
            payload: sortType,
        })
    }
}

export const setSelectedCampaignIds = (selectedCampaignIds: number[]) => {
    const selectedUniqCampaignIds = new Set(selectedCampaignIds)

    return (dispatch: Dispatch<DisplaySettingAction>) => {
        dispatch({
            type: DisplaySettingsActionType.SET_SELECTED_CAMPAIGN_IDS,
            payload: [ ...selectedUniqCampaignIds ],
        })
    }
}

export const setExpandableCampaignIds = (expandableCampaignIds: number[]) => {
    const uniqExpandableCampaignIds = [ ...new Set(expandableCampaignIds) ]

    return (dispatch: Dispatch<DisplaySettingAction>) => {
        dispatch({
            type: DisplaySettingsActionType.SET_EXPANDABLE_CAMPAIGN_IDS,
            payload: uniqExpandableCampaignIds,
        })
    }
}

export const setDataRepresentation = (dataRepresentation: dataRepresentationEnum) => {
    return (dispatch: Dispatch<DisplaySettingAction>) => {
        dispatch({
            type: DisplaySettingsActionType.SET_DATA_REPRESENTATION,
            payload: dataRepresentation,
        })
    }
}

export const setSelectedOptions = (selectedOptions: string[]) => {
    return (dispatch: Dispatch<DisplaySettingAction>) => {
        dispatch({
            type: DisplaySettingsActionType.SET_SELECTED_OPTIONS,
            payload: selectedOptions,
        })
    }
}

export const setPerPage = (perPage: number) => {
    return (dispatch: Dispatch<DisplaySettingAction>) => {
        dispatch({
            type: DisplaySettingsActionType.SET_PER_PAGE,
            payload: perPage,
        })
    }
}

export const setPageNo = (pageNo: number) => {
    return (dispatch: Dispatch<DisplaySettingAction>) => {
        dispatch({
            type: DisplaySettingsActionType.SET_PAGE_NO,
            payload: pageNo,
        })
    }
}
