import {Dispatch} from "redux";
import {SponsoredProductAction, SponsoredProductActionType} from "../types/sponsoredProducts";
import {ApiPlacementList, changeCampaignStatus} from "../../api/campaignApi";
import {copy} from "../../../../helper/system.helpers.functions";
import {IPlacementSubResult} from "../../types/placement";
import {IDataTableResult} from "../../types/childDataTable";
import {ICampaign} from "../../types/sponsoredProducts";
import {weekFilter} from "../../types/types";
import {stateByCampaignId} from "../../../../types/customers";

export const fetchSponsoredProduct = (data) => {
    return async (dispatch: Dispatch<SponsoredProductAction>) => {
        try {
            dispatch({ type: SponsoredProductActionType.FETCH_SPONSORED_PRODUCTS })
            const sponsoredProductData = await ApiPlacementList(data)
            const copySponsoredProductData = copy(sponsoredProductData)
            copySponsoredProductData.campaigns.forEach(item => {
                let defaultRecord = sponsoredProductData.defaultTargetAcos?.find(record =>
                    item.campaignName?.includes(record.type)
                )
                if (defaultRecord) {
                    item.defaultRecord = defaultRecord
                } else {
                    item.defaultRecord = sponsoredProductData.defaultTargetAcos?.find((data) => data.type == "All other")
                }
            })

            dispatch({
                type: SponsoredProductActionType.FETCH_SPONSORED_PRODUCTS_SUCCESS,
                payload: copySponsoredProductData
            })
        }
        catch (e) {
            dispatch({
                type: SponsoredProductActionType.FETCH_SPONSORED_PRODUCTS_ERROR,
                payload: 'sponsored product load error'
            })
        }
    }
}

interface getCampaignDataParams {
    campaigns: ICampaign[],
    placementSubResult: IPlacementSubResult[],
    dataTableResult: IDataTableResult[],
    datePeriodType: weekFilter
}

export const setCampaignData = (params) => {
    const {campaigns, placementSubResult, dataTableResult, datePeriodType} = params

    return async (dispatch: Dispatch<SponsoredProductAction>) => {
        const copyCampaignsData: ICampaign[] = copy(campaigns)
        if (placementSubResult.length) {
            copyCampaignsData.forEach(item => {
                placementSubResult.forEach(el => {
                    if (Number(el.campaignId) === item.campaignId) {
                        item.placementsData = el.placementsData
                    }
                })
            })
        }
        else {
            dataTableResult.forEach(el => {
                copyCampaignsData.forEach(item => {
                    if (Number(el.campaignId) === item.campaignId) {
                        item.subDataTable = el.subDataTable
                        item.datePeriodType = datePeriodType
                    }
                })
            })
        }

        dispatch({
            type: SponsoredProductActionType.SET_CAMPAIGN_DATA,
            payload: copyCampaignsData
        })
    }
}

export const updateCampaign = (campaign) => {
    return (dispatch: Dispatch<SponsoredProductAction>) => {
        dispatch({
            type: SponsoredProductActionType.CHANGE_CAMPAIGN,
            payload: campaign
        })
    }
}

export const fetchChangeCampaignsState = (stateByCampaignIds: stateByCampaignId[]) => {
    return async (dispatch: Dispatch<SponsoredProductAction>) => {
        try {
            await changeCampaignStatus({ stateByCampaignIds })

            dispatch({
                type: SponsoredProductActionType.FETCH_CAMPAIGN_STATE_SUCCESS,
                payload: stateByCampaignIds
            })
        }
        catch (e) {
            dispatch({
                type: SponsoredProductActionType.FETCH_CAMPAIGN_STATE_ERROR,
                payload: "Unable to change the status."
            })
        }
    }
}
