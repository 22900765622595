import React from "react";
import {useHelperFunctions} from "../hooks/useHelperFunctions";
import {weekFilterEnum} from "../types/types";

interface DateTableSettingsProps {
    campaignId: string,
}

const DateTableSettings: React.FC<DateTableSettingsProps> = ({ campaignId}) => {
    const { childHandler } = useHelperFunctions()

    return (
        <div className="week_filter">
            <button
                onClick={() => {
                    childHandler([campaignId], {type: weekFilterEnum.DAY})
                }}
            >
                Day
            </button>
            <button
                onClick={() => {
                    childHandler([campaignId], {type: weekFilterEnum.WEEK})
                }}
            >
                Week
            </button>
            <button
                onClick={() => {
                    childHandler([campaignId], {type: weekFilterEnum.MONTH})
                }}
            >
                Month
            </button>
        </div>
    )
};

export default DateTableSettings;
