
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { childBidHandler } from "../../../redux/action";
import VirtualizedTable from "../../component/virtualizedTable/VirtualizedTable";

const ExpandTable = ({
	placementData,
	defaultParams,
	campaignName,
	order,
	aovData,
	limitValue,
	defaultTargetAcos,
	bestArr,
	handleSelectTarget,
}) => {
	const {defaultBid,maxTargetCPC,minTargetCPC} = defaultParams
	const dispatch = useDispatch();
	const [subData, setSubData] = useState([]);

	useEffect(() => {
		if (placementData?.length > 0) {
			placementData?.map((el, index) => {

				let a = campaignName.split(/\s+/);
				const isWordIncludedInObject = (word, obj) => {
					return word.type.toLowerCase().includes(obj.toLowerCase());
				}
				let find = defaultTargetAcos.find(word => a.some(obj => isWordIncludedInObject(word, obj)));

				if (find == undefined) {
					find = defaultTargetAcos.find(x => x.type == "All other")
				}

				if (find.dynamicTarget == true) {
					placementData[index].targetAcos = findAcos(find?.dynamicTargetAcos, el.cvr);
				}
				else {
					placementData[index].targetAcos = find?.targetAcos;
				}
			})
		}
		setSubData(placementData);
	}, [placementData]);

	function findAcos(records, givenValue) {
		for (let record of records) {
			if (record.from <= givenValue && givenValue <= record.to) {
				return record.Acos;
			}
		}
		return 0; // Return null if no matching range is found
	}

	const columns = [
		{
			name: "",
			width: "50px",
			cell: (row) => {
				let arr = JSON.parse(localStorage.getItem('SBtarget')) || [];
				return (
					<input
						type="checkbox"
						defaultChecked={arr.includes(row.targetId)}
						onChange={(e) => handleSelectTarget(e, row.targetId)}
					/>
				);
			}
		},
		{
			name: "Target Acos",
			selector: (row) => row.targetAcos,
			cell: (e, rowIndex) => {

				const changeEvent = ($event) => {

					let eventValue = Number($event.target.value)
					let crrObj = subData;
					let acosVal = eventValue;
					let TotalSales = e.sales;
					let TotalClick = e.clicks;
					let result;
					let Sales = 0
					let Units = 0
					if (subData) {
						for (let i = 0; i < subData.length; i++) {
							Sales = Sales + subData[i].sales;
							Units = Units + subData[i].orders;
						}
					}

					let AOV //if AOV  is set on setting page then, take that AOV, ELSE AOV =sales/units
					if (order == 0) {
						aovData.forEach(element => {
							let uppercase = element.campaignName.toUpperCase()
							let name = new RegExp(uppercase, 'i')
							if (name.test(campaignName)) {
								AOV = element.value
							}
						});
					}
					else {
						AOV = Sales / Units
					}

					if (!AOV) {
						AOV = Sales / Units
					}

					if (crrObj[rowIndex].orders == 0 && crrObj[rowIndex].clicks >= 4) {
						result = (1 / (crrObj[rowIndex].clicks + 1)) * (AOV) * (acosVal / 100)
					}

					else if (crrObj[rowIndex].sales == 0 && crrObj[rowIndex].clicks < 4) {
						result = (Number(AOV) / (Number(crrObj[rowIndex].clicks) + 1)) * (eventValue / 100)
					}

					else { // else
						if (crrObj[rowIndex].orders <= 3 && crrObj[rowIndex].cvr >= 50) { //if orders <=0 and cvr>=50
							result = crrObj[rowIndex].cpc + (crrObj[rowIndex].cpc * 0.5) // old cpc+ 50% oldcpc
						}
						else {// rest placements
							result = (TotalSales / TotalClick) * (eventValue / 100);
						}
					}

					let finalVal = result.toFixed(2)

					if (isNaN(finalVal)) {
						finalVal = 0
					}

					crrObj[rowIndex].targetAcos = eventValue == '' ? 0 : eventValue;

					crrObj[rowIndex].targetCPC = eventValue != '' ? Number(finalVal) < minTargetCPC ? minTargetCPC : Number(finalVal) > maxTargetCPC ? maxTargetCPC : Number(finalVal) : 0;
					setSubData([...subData]);

					// ------------tatgetcpc condtions---------//
					HandleTargetCpcCondition(crrObj, rowIndex)

					// ----------- update campaign data --------//
					dispatch(childBidHandler())
				}

				return (
					<>
						<input onChange={changeEvent} value={e.targetAcos} type="text" className="cal" />
					</>
				);
			},

		},
		{
			name: "Campaigns",
			selector: (row) => row.targetName,
			sortable: true,
			cell: (e) => {
				return <>
					{
						e.targetName ?
							<div className={e.targetStatus == "ENABLED" ? "green_dot dot" : "red_dot dot"}>
								<p></p>
								<div>{e.targetName}</div>
							</div>
							:
							"-"
					}
				</>;
			},
		},
		{
			name: "Target CPC In Amazon",
			sortable: true,
			width: "80px",
			selector: (row) => `$${row.tagetCPCAmazon}`,
		},
		{
			name: "Target CPC",
			sortable: true,
			selector: (row) => `$${row.targetCPC}`,
		},
		{
			name: "Best CPC",
			sortable: true,
			selector: (row) => row.targetCVRCPC == 1 ? row.targetCPC : row.bestCVRCPC,
			cell: (e) => {
				if (bestArr.includes(e.campaignId)) {
					return `${e.bestCPC || 0}`
				}
				else {
					return `${e.targetCVRCPC == 1 ? e.targetCPC || 0 : e.bestCVRCPC || 0}`
				}
			}
		},
		{
			name: 'Limited CPC',
			selector: (row) => `$${row.limitedCPC}`,
			cell: (e, rowIndex) => {
				const changeEvent = ($event) => {
					let eventValue = Number($event.target.value)
					e.limitedCPC = eventValue;
					subData[rowIndex].limitedCPC = eventValue;
					setSubData([...subData]);

					dispatch(childBidHandler())
				}
				return (
					<>
						<input onChange={changeEvent}
							value={e.limitedCPC || 0}
							type="number"
							className="cal"
							inputMode="numeric"
						/>
					</>
				)
			}
		},
		{
			name: "Impressions",
			selector: (row) => row.impressions,
		},
		{
			name: "Clicks",
			selector: (row) => row.clicks,
		},
		{
			name: "CTR%",
			selector: (row) => row.ctr,
			cell: (e) => (
				`${e.ctr}%`
			)
		},
		{
			name: "Spend",
			selector: (row) => row.spend,
			cell: (e) => (
				`$${e.spend}`
			)

		},
		{
			name: "Sales",
			selector: (row) => row.sales,
			cell: (e) => (
				`$${e.sales}`
			)
		},
		{
			name: "Orders",
			selector: (row) => row.orders,
		},
		{
			name: "Units",
			selector: (row) => row.units,
		},
		{
			name: "CVR%",
			selector: (row) => row.cvr,
			cell: (e) => (
				`${e.cvr}%`
			)
		},
		{
			name: "ACOS%",
			selector: (row) => row.acos,
			cell: (e) => (
				`${e.acos}%`
			)
		},
		{
			name: "CPC",
			selector: (row) => row.cpc,
			cell: (e) => (
				`$${e.cpc}`
			)
		},
		{
			name: "ROAS",
			selector: (row) => row.roas,
		},

	];

	const cal = () => {
		if (!placementData?.length) return; // Early exit if placementData is empty or undefined

		let Sales = 0;
		let Units = 0;

		placementData.forEach(({ sales, orders }) => {
			Sales += sales;
			Units += orders;
		});

		let AOV = Units > 0 ? Sales / Units : 0;

		placementData.forEach((row, rowIndex) => {
			const { targetAcos: eventValue, sales: TotalSales, clicks: TotalClick, orders, cvr, cpc, campaignName } = row;
			let result;

			if (order === 0) {
				const matchedAOV = aovData.find(({ campaignName: aovName }) =>
					new RegExp(aovName.toUpperCase(), "i").test(campaignName)
				);
				AOV = matchedAOV ? matchedAOV.value : AOV;
			}

			AOV = AOV || (Units > 0 ? Sales / Units : 0);

			if (orders === 0 && TotalClick >= 4) {
				result = (1 / (TotalClick + 1)) * AOV * (eventValue / 100);
			} else if (TotalSales === 0 && TotalClick < 4) {
				result = (AOV / (TotalClick + 1)) * (eventValue / 100);
			} else if (orders <= 3 && cvr >= 50) {
				result = cpc + cpc * 0.5;
			} else {
				result = (TotalSales / TotalClick) * (eventValue / 100);
			}

			const finalVal = Math.max(
				minTargetCPC,
				Math.min(maxTargetCPC, Number(result?.toFixed(2)) || 0)
			);

			row.targetCPC = eventValue !== "" ? finalVal : 0;

			HandleTargetCpcCondition(placementData, rowIndex);
		});
		dispatch(childBidHandler());
	};

	const HandleTargetCpcCondition = (crrObj, rowIndex) => {
		if (rowIndex !== 2) {
			crrObj.forEach((el) => calLimiatedCpcANDtagetcpcAmazon(el));
			return;
		}

		let all = 0;
		let one = 0;
		let two = 0;
		let placementWithClicksAndSale = null;
		const placementWithoutClicksAndSale = [];

		crrObj.forEach((place) => {
			if (place.clicks < 4 && place.orders === 0) {
				all++;
			}
			if (place.clicks > 0 && place.orders > 0) {
				one++;
				placementWithClicksAndSale = place;
			} else if (place.clicks >= 4 && place.orders === 0) {
				one++;
				placementWithClicksAndSale = place;
			} else if (place.clicks < 4 && place.orders === 0) {
				two++;
				placementWithoutClicksAndSale.push(place);
			}
		});

		if (one === 1 && two === 2) {
			placementWithoutClicksAndSale.forEach((el) => {
				const index = crrObj.findIndex((x) => x === el);
				if (index !== -1) {
					crrObj[index].targetCPC = placementWithClicksAndSale.targetCPC;
				}
			});
		} else {
			crrObj.forEach((place, i) => {
				if (place.orders === 0 && place.clicks < 4) {
					const nextIndex = (i + 1) % crrObj.length;
					const nextNextIndex = (i + 2) % crrObj.length;

					const lowestCPC = Math.min(
						crrObj[nextIndex]?.targetCPC || 0,
						crrObj[nextNextIndex]?.targetCPC || 0
					);
					crrObj[i].targetCPC = lowestCPC;
				}
			});
		}

		crrObj.forEach((el) => calLimiatedCpcANDtagetcpcAmazon(el));
	};

	// const calLimiatedCpcANDtagetcpcAmazon = (e) => {
	// 	let targetCpcAmazon;
	// 	e.tagetCPCAmazon = e?.bid ? e?.bid.toFixed(2) : 0;
	// 	targetCpcAmazon = e.tagetCPCAmazon;
	// 	let limitedCPCValue = 0;

	// 	const M7 = parseFloat(targetCpcAmazon);
	// 	const N7 = parseFloat(e.targetCPC);
	// 	const acosRange = limitValue.find(x => e.acos >= x.from && e.acos <= x.to)
	// 	const H3 = acosRange?.increaseBid;
	// 	const H4 = acosRange?.decreaseBid;

	// 	const calculateResult = (M7, N7, H3, H4) => {
	// 		if (M7 <= N7) {
	// 			return Math.min(M7 * (1 + H3 / 100), N7);
	// 		} else {
	// 			return Math.max(M7 * (1 - H4 / 100), N7);
	// 		}
	// 	}

	// 	limitedCPCValue = calculateResult(M7, N7, H3, H4);
	// 	e.limitedCPC = limitedCPCValue

	// 	let val = Number(limitedCPCValue) < minTargetCPC ? minTargetCPC : Number(limitedCPCValue) > maxTargetCPC ? maxTargetCPC : Number(limitedCPCValue)
	// 	e.limitedCPC = val.toFixed(2)
	// }

	const calLimiatedCpcANDtagetcpcAmazon = (e) => {
		//  targetcpcamazon (calculate based on keywordbid(new bidadjustemnt main))//
		let targetCpcAmazon;
		let queryResult = (Number(e.bid) * Number(e.limitedCPC) || 0) / 100;
		let finalResult = Number(queryResult) + Number(e.bid);
		e.tagetCPCAmazon = e?.bid ? e?.bid.toFixed(2) : 0;

		// targetCpcAmazon = finalResult ? finalResult.toFixed(2) : 0;
		targetCpcAmazon = e.tagetCPCAmazon;
		let limitedCPCValue = 0;

		const M7 = parseFloat(targetCpcAmazon);
		const N7 = parseFloat(e.targetCPC);
		const acosRange = limitValue.find(x => e.acos >= x.from && e.acos <= x.to)
		const H3 = acosRange?.increaseBid;
		const H4 = acosRange?.decreaseBid;

		const calculateResult = (M7, N7, H3, H4) => {
			if (M7 <= N7) {
				return Math.min(M7 * (1 + H3 / 100), N7);
			} else {
				return Math.max(M7 * (1 - H4 / 100), N7);
			}
		}

		limitedCPCValue = calculateResult(M7, N7, H3, H4);
		e.limitedCPC = limitedCPCValue

		let val = Number(limitedCPCValue) < minTargetCPC ? minTargetCPC : Number(limitedCPCValue) > maxTargetCPC ? maxTargetCPC : Number(limitedCPCValue)
		e.limitedCPC = e?.clicks <= 4 ?  defaultBid: val.toFixed(2)
	}

	useEffect(() => {
		if (placementData != undefined) {
			cal(placementData)
		}
	}, [placementData]);

	useEffect(() => {
		if (placementData != undefined) {
			placementData?.map((crrObj) => {
				let arr = JSON.parse(localStorage.getItem('SBtarget')) || [];
				if (crrObj.clicks > 4 && (crrObj.limitedCPC != crrObj.tagetCPCAmazon)) {
					if (!arr.includes(crrObj.targetId)) {
						arr.push(crrObj.targetId);
						localStorage.setItem('SBtarget', JSON.stringify(arr));
					}
				}
				if (bestArr.includes(crrObj.campaignId)) {
					let bestCPC = crrObj.targetCVRCPC == 1 ? crrObj.targetCPC : crrObj.bestCVRCPC;
					crrObj.bestCPC = bestCPC;
					crrObj.limitedCPC = bestCPC >= crrObj.targetCPC ? bestCPC : crrObj.targetCPC;
					crrObj.targetCPC = bestCPC >= crrObj.targetCPC ? bestCPC : crrObj.targetCPC;
				}
			})
		}
	}, [placementData, bestArr]);

	return (
		<>
			<VirtualizedTable data={subData} columns={columns} />
			{/* <DataTable
				className="table_content"
				columns={columns}
				data={subData}
				fixedHeader
				loading={false}
				progressPending={false}
			/> */}
		</>
	);
}

export default ExpandTable;