import $api from "../../axios";
import axios, {AxiosError, AxiosResponse} from "axios";
import {IApiAuth} from "../../modules/login/types/apiAuth";
const api = `/customer/`

class ApiAuth {
    static login = async (data): Promise<AxiosResponse<IApiAuth> | AxiosError<Error>> => {
        try {
            return await $api.post<IApiAuth>(`${api}login`, data)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e
            }
            throw new Error('Internal server error')
        }
    }

    static logout = () => {
        return $api.get(`${api}logout`)
            .then(res => res.data).catch(res => res.data)
    }
}

export default ApiAuth
