import $api from "../../axios";

const api = `/placement/`

const ApiPlacementListByCampaign = (data) => {
    return $api.post(`${api}getPlacementByCampaign`, data)
        .then(res => res.data).catch(res => res.data)
}
const ApiStore = (data) => {
    return $api.post(`${api}updateCampaign`, data)
        .then(res => res.status).catch(res => res.status)
}

const ApiPlacementListByCampaignLogHistory=(data)=>{
    return $api.post(`${api}campaignLogHistory`, data)
    .then(res => res.data).catch(res => res.data)
}
export {
    ApiPlacementListByCampaign,
    ApiStore,
    ApiPlacementListByCampaignLogHistory
}
