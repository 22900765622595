import React from "react";
import {weekFilter, weekFilterEnum} from "../types/types";

interface ExpandTableRowsProps {
    datePeriodFilter: weekFilter
}

const ExpandTableRows: React.FC<ExpandTableRowsProps> = ({ datePeriodFilter }) => {
    function getPeriodName() {
        switch (datePeriodFilter) {
            case weekFilterEnum.DAY:
                return "Day"
            case weekFilterEnum.WEEK:
                return "Week"
            case weekFilterEnum.MONTH:
                return "Month"
            default:
                return ""
        }
    }

    return (
        <thead>
            <tr>
                <th className="date_show">
                    {getPeriodName()}
                </th>
                <th>Organic Position</th>
                <th>Sponsored Position</th>
                <th>PPC Sales</th>
                <th>PPC Cost</th>
                <th> Profits</th>
                <th>Profit Margin</th>
                <th>ACOS</th>
                <th>Orders</th>
                <th>Units</th>
                <th>Conversion</th>
                <th>CPC</th>
                <th>Impressions</th>
                <th>Clicks</th>
                <th>CTR</th>
                <th>Cost Per Order</th>
                <th>Avg Sales Price</th>
            </tr>
        </thead>
    )
};

export default ExpandTableRows;
