
export const MATCH_TYPES = {
    ALL: "All",
    RANKING: "Ranking",
    EXACT: "Exact",
    PHRASE: "Phrase",
    BROAD: "Broad",
    AUTO: "Auto",
    ASIN: "ASIN",
    ALL_NON_RANKING: "All non ranking"
}

export const MATCH_TYPES_ENUM = [
    MATCH_TYPES.ALL,
    MATCH_TYPES.AUTO,
    MATCH_TYPES.ALL_NON_RANKING,
    MATCH_TYPES.EXACT,
    MATCH_TYPES.ASIN,
    MATCH_TYPES.BROAD,
    MATCH_TYPES.PHRASE
]

export const ORDER_TYPES =  {
    ORDERS: "Orders",
    DATE: "Date"
}
