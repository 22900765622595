import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useActions} from "../../../hooks/useActions";
import {ApiChild} from "../api/campaignApi";
import {weekFilterEnum} from "../types/types";
import {ORDER_TYPES} from "../constants";
import {loadDataFilterEnum} from "../../../types/settings";
import {dataRepresentationEnum} from "../../../types/customers";

export const useHelperFunctions = () => {
    const { fetchSponsoredProduct, setCampaignData } = useActions()
    const { campaignData } = useTypedSelector((select) => select.sponsoredProduct)
    const { dateRange, isOrder} = useTypedSelector((select) => select.filter)
    const {
        dataRepresentationType,
        selectedOptions,
        sortType,
        fieldName,
        perPage,
    } = useTypedSelector((select) => select.displaySettingsSp)

    const childHandler = (campaignIds, changedData = {}) => {
        const profileId = localStorage.getItem("profileId");
        let data = {
            name: dataRepresentationType,
            profileId: profileId,
            type: weekFilterEnum.DAY,
            campaignNames: campaignIds,
            fromDate: dateRange.from,
            toDate: dateRange.to,
            filterIds: selectedOptions,
            filterType: dataRepresentationType === dataRepresentationEnum.DATA_TABLE || !isOrder
                ? loadDataFilterEnum.DATE
                : loadDataFilterEnum.ORDERS,
            ...changedData
        }

        ApiChild(data)
            .then((res) => {
                setCampaignData({
                    campaigns: campaignData.campaigns,
                    placementSubResult: res.placementSubResult || [],
                    dataTableResult: res.dataTableResult || [],
                    datePeriodType: res.datePeriodType
                })
            })
    }

    const getCampaignData = async (changedData = {}) => {
        const profileId = localStorage.getItem("profileId");
        if (dateRange.from && dateRange.to) {
            let data = {
                profileId: profileId,
                fieldName: fieldName,
                sortType: sortType,
                fromDate: dateRange.from,
                toDate: dateRange.to,
                filterType: isOrder ? ORDER_TYPES.ORDERS : ORDER_TYPES.DATE,
                perPage: perPage,
                filterIds: selectedOptions,
                ...changedData,
            }

            fetchSponsoredProduct(data)
        }
    }


    return {
        childHandler,
        getCampaignData
    }
}

