import React from "react";
import {Dropdown, MenuProps} from "antd";
import {setStateType} from "../modules/sponsoredProducts/types/setStateType";
import {ICampaign} from "../modules/sponsoredProducts/types/sponsoredProducts";
import {campaignStates, stateByCampaignId} from "../types/customers";

enum options {
    "PAUSE" = "Pause",
    "ENABLE" = "Enable"
}

interface CampaignActionsProps {
    selectedCampaignIds: string[],
    setSelectedCampaignIds: setStateType<string[]>,
    changeCampaignState: Function,
    setCampaignData: setStateType<any[]>,
    campaignData: any[],
}

const CampaignActions: React.FC<CampaignActionsProps> = ({selectedCampaignIds, setSelectedCampaignIds, changeCampaignState, setCampaignData, campaignData}) => {
    function changeStateCampaign(state: campaignStates) {
        const stateByCampaignIds: stateByCampaignId[] = selectedCampaignIds.map((campaignId) => {
            return {
                campaignId: Number(campaignId),
                state
            }
        })

        changeCampaignState({ stateByCampaignIds })
        setSelectedCampaignIds([])
        changeCampaignData(stateByCampaignIds, state)
    }

    function changeCampaignData (stateByCampaignIds: stateByCampaignId[], state: campaignStates) {
        setCampaignData(
            campaignData.map((prevCampaign) => {
                const isCampaignWithChangeState = stateByCampaignIds.find((campaign) => String(campaign.campaignId) === prevCampaign.campaignId)
                if (isCampaignWithChangeState) {
                    return {
                        ...prevCampaign,
                        campaignStatus: state
                    }
                }

                return prevCampaign
            })
        )
    }

    const handleMenuClick: MenuProps["onClick"] = (event) => {
        switch (event.key) {
            case options.PAUSE:
                changeStateCampaign(campaignStates.PAUSED)
                break
            case options.ENABLE:
                changeStateCampaign(campaignStates.ENABLED)
                break
        }
    }

    const items: MenuProps['items'] = [
        {
            label: options.PAUSE,
            key: options.PAUSE,
        },
        {
            label: options.ENABLE,
            key: options.ENABLE,
        },
    ];

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    return (
        <Dropdown.Button menu={menuProps}>
            State
        </Dropdown.Button>
    )
}

export default CampaignActions

