import {dataRepresentationEnum, matchTypesEnum} from "../../../../types/customers";

export enum DisplaySettingsActionType {
    SET_SELECTED_OPTIONS = "SET_SELECTED_OPTIONS",
    SET_BEST_ARR = "SET_BEST_ARR",
    SET_FIELD_NAME = "SET_FIELD_NAME",
    SET_SORT_TYPE = "SET_SORT_TYPE",
    SET_SELECTED_CAMPAIGN_IDS = "SET_SELECTED_CAMPAIGN_IDS",
    SET_EXPANDABLE_CAMPAIGN_IDS = "SET_EXPANDABLE_CAMPAIGN_IDS",
    SET_DATA_REPRESENTATION = "SET_DATA_REPRESENTATION",
    SET_PER_PAGE = "SET_PER_PAGE",
    SET_PAGE_NO = "SET_PAGE_NO",
}

interface SetSelectedOptions {
    type: DisplaySettingsActionType.SET_SELECTED_OPTIONS,
    payload: string[],
}

interface SetBestArr {
    type: DisplaySettingsActionType.SET_BEST_ARR,
    payload: number[],
}

interface SetFieldName {
    type: DisplaySettingsActionType.SET_FIELD_NAME,
    payload: string,
}

interface SetSortType {
    type: DisplaySettingsActionType.SET_SORT_TYPE,
    payload: number,
}

interface SetSelectedCampaignIds {
    type: DisplaySettingsActionType.SET_SELECTED_CAMPAIGN_IDS,
    payload: number[],
}

interface SetExpandableCampaignIds {
    type: DisplaySettingsActionType.SET_EXPANDABLE_CAMPAIGN_IDS,
    payload: number[],
}

interface SetDataRepresentationType {
    type: DisplaySettingsActionType.SET_DATA_REPRESENTATION,
    payload: dataRepresentationEnum,
}

interface SetPerPage {
    type: DisplaySettingsActionType.SET_PER_PAGE,
    payload: number,
}

interface SetPageNo {
    type: DisplaySettingsActionType.SET_PAGE_NO,
    payload: number,
}

export type DisplaySettingAction =
    SetSelectedOptions |
    SetBestArr |
    SetFieldName |
    SetSortType |
    SetSelectedCampaignIds |
    SetExpandableCampaignIds |
    SetDataRepresentationType |
    SetPerPage |
    SetPageNo

export interface DisplaySettingsState {
    selectedOptions: string[],
    bestArr: any[],
    fieldName: string,
    sortType: number,
    selectedCampaignIds: number[],
    expandableCampaignIds: number[],
    dataRepresentationType: dataRepresentationEnum,
    perPage: number,
    pageNo: number,
}
