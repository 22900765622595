import {useActions} from "../../../hooks/useActions";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {ApiOrderSave} from "../../../api-wrapper/campaign/ApiCampaignName";
import {useEffect, useState} from "react";
import styles from "../styles/order.module.scss"

import { message, Spin} from "antd"
import { LoadingOutlined } from "@ant-design/icons";
import Loader from "../../../components/Loader/Loader";

const Order = () => {
    const { setDefaultOrder, fetchDefaultOrder } = useActions()
    const { defaultOrder, error } = useTypedSelector((select) => select.filter)
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const profileId = localStorage.getItem("profileId");

    useEffect(() => {
        fetchDefaultOrder(profileId)
    }, [])

    useEffect(() => {
        if (error) {
            message.error(error)
        }
    }, [error])

    const HandleOrderSave = () => {
        setIsLoaded(true)
        const profileId = localStorage.getItem("profileId");

        let data = {
            profileId: profileId,
            defaultOrder: defaultOrder
        }

        ApiOrderSave(data)
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    message.success('success')
                    return
                }
                message.error('save default order error')
            })
            .finally(() => {
                setIsLoaded(false)
            })
    }

    return (
        <div className="order_input">
            <input
                type="text"
                name="order"
                onChange={(event) => setDefaultOrder(Number(event.target.value))}
                value={defaultOrder}
            />
            <button
                className="add_filter order-btn  btn btn-primary"
                type="submit"
                onClick={HandleOrderSave}
            >
                {
                    !isLoaded
                        ?
                        'Save'
                        :
                        <Loader
                            variant={'white'}
                        />
                }
            </button>
        </div>
    )
}

export default Order;
