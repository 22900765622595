import React from "react"
import {Input} from "antd"
import { SearchOutlined } from '@ant-design/icons'
import style from "./rtSearchInput.module.scss"

interface RtSearchInputProps {
    onChange: (value: React.ChangeEvent<HTMLInputElement>) => void,
    value: string,
    placeholder: string
}

const RtSearchInput: React.FC<RtSearchInputProps> = ({onChange, value, placeholder}) => {

    return (
            <Input
                className={style.search_input + " ml-2"}
                onChange={onChange}
                onInput={onChange}
                value={value}
                addonBefore={<SearchOutlined />}
                placeholder={placeholder}
            />
    )
}

export default RtSearchInput
