import Filters from "../modules/filter/components/Filters";
import DataContent from "../modules/sponsoredProducts/components/DataContent";

export const SponsoredProducts = () => {
    return (
        <>
            <Filters
                showOrder={true}
                showDate={true}
            />
            <div className="middle_container">
                <div className="data_content mt-0 mb-1 mb-md-2">
                    <DataContent/>
                </div>
            </div>
        </>
    )
}

export default SponsoredProducts
