import axios, { AxiosResponse } from "axios";
import { IRefresh } from "../types/login";

const $api = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_BASE,
});

$api.interceptors.request.use((config) => {
    const token = localStorage.getItem("sellerToken");
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    config.headers["Content-Type"] = "application/json"
    config.headers["profileId"] = 2295886804774594
    return config;
});

let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

const addRefreshSubscriber = (callback: (token: string) => void) => {
    refreshSubscribers.push(callback);
};

$api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config

        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true

            if (isRefreshing) {
                return new Promise((resolve) => {
                    addRefreshSubscriber((newToken) => {
                        originalRequest.headers["Authorization"] = `Bearer ${newToken}`
                        resolve($api(originalRequest))
                    })
                })
            }
            isRefreshing = true

            try {
                const tokenData = await $api.get<IRefresh, AxiosResponse<IRefresh>>(`/customer/refresh`)
                const newAccessToken = tokenData.data.accessToken
                localStorage.setItem("sellerToken", newAccessToken)

                refreshSubscribers.forEach((callback) => callback(newAccessToken))
                refreshSubscribers = []

                originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`
                return $api(originalRequest)
            } catch (e) {
                console.error("Error updating token:", e)
                localStorage.removeItem("sellerToken")
                window.location.href = '/login'
                return Promise.reject(e)
            } finally {
                isRefreshing = false
            }
        }

        return Promise.reject(error);
    }
);

export default $api;
