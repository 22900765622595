import $api from "../../axios";
import {AxiosResponse} from "axios";

const api = `/placement/`
const settingApi = `/setting/`
const filterApi = `/filter/`

const ApiPlacementList = (data) => {
    return $api.post(`${api}getPlacement`, data)
        .then(res => res.data).catch(res => res.data)
}

const ApiPlacementListMange = (data) => {
    return $api.post(`${settingApi}getUnmanagedCampaign`, data)
        .then(res => res.data).catch(res => res.data)
}

const ApiFilterList = (data) => {
    return $api.post(`${filterApi}getAllFilter`, data)
        .then(res => res.data).catch(res => res.data)
}

const ApiCustomFilter = (data) => {
    return $api.post(`${filterApi}getAllFilterWithOutPagination`, data)
        .then(res => res.data).catch(res => res.data)
}


const ApiChild = (data) => {
    return $api.post(`${api}getChildData`, data)
        .then(res => res.data).catch(res => res.data)
}

const ApiUtilityGenerateReport = (data) => {
    return $api.post(`${api}utilityGenerateReport`, data)
        .then(res => res.data).catch(res => res.data)
}

const ApiGetUtilityHistory = (data) => {
    return $api.post(`${api}getUtilityHistory`, data)
        .then(res => res.data).catch(res => res.data)
}

const ApiUnmange = (data) => {
    return $api.post(`${settingApi}updateUnmanagedCampaign`, data)
        .then(res => res.status).catch(res => res.status)
}

const ApiOrderSave = (data): Promise<AxiosResponse<{}>> => {
    return $api.post(`${api}saveDefaultOrder`, data)
        .then(res => res).catch(res => res)
}

const ApireverseBidChange = (data) => {
    return $api.post(`${api}reverseBidChange`, data)
        .then(res => res.data).catch(res => res.data)
}

export {
    ApiPlacementListMange,
    ApiOrderSave,
    ApiUnmange,
    ApiCustomFilter,
    ApiPlacementList,
    ApiFilterList,
    ApiChild,
    ApiUtilityGenerateReport,
    ApiGetUtilityHistory,
    ApireverseBidChange
}

