import React from "react"
import {LoadingOutlined} from "@ant-design/icons";
import style from "./cardLoader.module.scss"
import {Spin} from "antd";

const CardLoader: React.FC = () => {

    return (
        <Spin
            className={style.spin_card}
            size="large"
            indicator={
                <LoadingOutlined spin/>
            }
        />
    )
}

export default CardLoader
