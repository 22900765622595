import {Controller} from "../../../helper/links/link";
import React from "react";
import {ControllerRenderProps} from "react-hook-form/dist/types/controller";
import {Control, UseFormRegister} from "react-hook-form/dist/types/form";
import {FieldErrors} from "react-hook-form";
import {formValue} from "../types/login";

interface EmailControllerProps {
    control: Control<formValue>,
    register: UseFormRegister<formValue>,
    errors: FieldErrors<formValue>
}

const EmailController: React.FC<EmailControllerProps> = ({
        control,
        register,
        errors
    }) => {
    const inputHandler = (e: React.ChangeEvent<HTMLInputElement>, field: ControllerRenderProps<formValue, "email">) => {
        field.onChange(e.target.value)
    }

    return <div className="user-box">
        <Controller
            control={control}
            {...register("email")}
            render={({field}) => (
                <input
                    {...field}
                    className="user-box-input"

                    value={field.value}
                    onChange={(e) => inputHandler(e, field)}
                />
            )}
        />
        <label className="user-box-label">Email</label>
        {errors.email && <p className="error">{errors.email.message}</p>}
    </div>
}

export default EmailController
