import React from "react";
import {Tooltip} from "antd";
import {iAsin} from "../types/childDataTable";
import {matchTypesEnum} from "../../../types/customers";

interface ProductPosition {
    position: number | number[] | undefined,
    asins: iAsin[],
    campaignName: string
}

const ProductPosition: React.FC<ProductPosition> = ({ position, asins, campaignName}) => {
    function showPosition() {
        const isEmptyArray = Array.isArray(position) && position.length === 0

        if (!position || isEmptyArray) {
            return 0
        }
        if (Array.isArray(position)) {
            const numberPositions = position.map((position) => Number(position))
            return numberPositions.sort().join(", ")
        }
        return position
    }

    function getTooltip() {
        const asinForScrape = asins.find((asin) => asin.forScrape)

        if (asinForScrape?.asin) {
            return "asin " + asinForScrape.asin
        }
        return "No ASIN selected for position tracking"
    }

    const isExactOrRanking = campaignName.includes(matchTypesEnum.EXACT) || campaignName.includes(matchTypesEnum.RANKING)
    return (
        isExactOrRanking ?
            <Tooltip
                placement="top"
                title={getTooltip()}
            >
                {showPosition()}
            </Tooltip>
        :
            <Tooltip
                placement="top"
                title={"Tracking of positions is only available for Ranking and Exact."}
            >
                -
            </Tooltip>
    )
};

export default ProductPosition
