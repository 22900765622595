import $api from "../../axios";
import {changeCampaignStatusBody} from "../../types/customers";

const api = `/SD/`
const settingApi = `/setting/`

const ApiCampaignList = (data) => {
    return $api.post(`${api}campaign/getSDCampaign`, data)
        .then(res => res.data).catch(res => res.data)
}
const ApiUnmange = (data) => {
    return $api.post(`${settingApi}updateUnmanagedCampaign`, data)
        .then(res => res.data).catch(res => res.data)
}
const ApiChild = (data) => {
    return $api.post(`${api}target/getTargetData`, data)
        .then(res => res.data).catch(res => res.data)
}

const ApisaveInAmazon = (data) => {
    return $api.post(`${api}target/updateSDTarget`, data)
        .then(res => res.data).catch(res => res.data)
}

const changeCampaignState = async (data: changeCampaignStatusBody) => {
    return await $api.put(`${api}campaign/updateCampaignsState`, data)
}

export {
    ApiCampaignList,
    ApiUnmange,
    ApiChild,
    ApisaveInAmazon,
    changeCampaignState
}

