import React from "react";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useExpandTableHelperFunctions} from "../hooks/useExpandTableHelperFunctions";
import {copy} from "../../../helper/system.helpers.functions";
import {useActions} from "../../../hooks/useActions";

const useExpandColumns = ({order, campaignName, campaignId, keywordBid, subData}) => {
    const { bestArr } = useTypedSelector((select) => select.displaySettingsSp)
    const { campaignData } = useTypedSelector((select) => select.sponsoredProduct)
    const { HandleChildBidAdjustment, HandleTargetCpcCondition } = useExpandTableHelperFunctions()

    const {updateCampaign} = useActions()

    const changeTargetAcosEvent = ($event, rowIndex, e) => {
        const acosVal = Number($event.target.value)
        const subDataLocal = subData
        const TotalSales = e.sales;
        const TotalClick = e.clicks;
        let Sales = 0
        let Units = 0
        if (subDataLocal) {
            for (let i = 0; i < subDataLocal.length; i++) {
                Sales = Sales + subDataLocal[i].sales;
                Units = Units + subDataLocal[i].orders;
            }
        }

        let AOV
        if (order == 0) {
            campaignData.getAOVData.forEach(element => {
                if (element.campaignName.toLowerCase() === campaignName.toLowerCase()) {
                    AOV = element.value
                }
            });
        }
        else {
            AOV = Sales / Units
        }
        if (!AOV) {
            AOV = Sales / Units
        }
        let result = 0

        if (subDataLocal[rowIndex].orders == 0 && subDataLocal[rowIndex].clicks >= 4) {
            result = (1 / (subDataLocal[rowIndex].clicks + 1)) * (AOV) * (acosVal / 100)
        }

        else if (subDataLocal[rowIndex].sales == 0 && subDataLocal[rowIndex].clicks < 4) {
            result = (Number(AOV) / (Number(subDataLocal[rowIndex].clicks) + 1)) * (acosVal / 100)
        }

        else {
            if (subDataLocal[rowIndex].orders <= 3 && subDataLocal[rowIndex].cvr >= 50) {
                result = subDataLocal[rowIndex].cpc + (subDataLocal[rowIndex].cpc * 0.5)
            }
            else {
                result = (TotalSales / TotalClick) * (acosVal / 100);
            }
        }

        let finalVal = Number(result.toFixed(2))
        if (isNaN(finalVal)) {
            finalVal = 0
        }
        subDataLocal[rowIndex].targetAcos = acosVal || 0

        if (bestArr.includes(e.campaignId)) {
            subDataLocal[rowIndex].targetCPC = subDataLocal[rowIndex].targetCVRCPC == 1 ? subDataLocal[rowIndex].targetCPC : subDataLocal[rowIndex].bestCVRCPC
        }
        else {
            subDataLocal[rowIndex].targetCPC = acosVal ? Number(finalVal) < campaignData.minTargetCPC ? campaignData.minTargetCPC : Number(finalVal) > campaignData.maxTargetCPC ? campaignData.maxTargetCPC : Number(finalVal) : 0;
        }
        HandleTargetCpcCondition(subDataLocal, rowIndex, keywordBid)
        const campaign = HandleChildBidAdjustment({
            placement: subDataLocal,
            keywordBid,
        })
        updateCampaign(campaign)
    }

    const changeLimitedCpcEvent = ($event: React.ChangeEvent<HTMLInputElement>, rowIndex: number) => {
        let eventValue = Number($event.target.value)
        const copySubData = copy(subData)
        copySubData[rowIndex].limitedCPC = eventValue

        const campaign = HandleChildBidAdjustment({
            placement: copySubData,
            keywordBid,
        })
        updateCampaign(campaign)
    }

    return [
        {
            name: "Target Acos",
            selector: (row) => row.targetAcos,
            cell: (e, rowIndex) =>
                <>
                    <input onChange={(event) => changeTargetAcosEvent(event, rowIndex, e)} value={e.targetAcos} type="text" className="cal" />
                </>
        },
        {
            name: "placement",
            selector: (row) => row.placement,
        },
        {
            name: "Bid Adjustment",
            width: "80px",
            selector: (row) => row.placementModifier ?? "-",
            cell: (e) => `${e.placementModifier ? e.placementModifier : 0}%`
        },
        {
            name: "Target CPC In Amazon",
            sortable: true,
            width: "80px",
            selector: (row) => row.tagetCPCAmazon,
            cell: (e) => `$${e.tagetCPCAmazon}`
        },
        {
            name: "Target CPC",
            sortable: true,
            selector: (row) => `$${row.targetCPC}`,
        },
        {
            name: "Best CPC",
            sortable: true,
            selector: (row) => row.targetCVRCPC == 1 ? row.targetCPC : row.bestCVRCPC,
            cell: (e) => {
                if (bestArr.includes(e.campaignId)) {
                    return e.bestCPC
                }
                else {
                    return e.targetCVRCPC == 1 ? e.targetCPC || 0 : e.bestCVRCPC || 0
                }
            }
        },
        {
            name: 'Limited CPC',
            selector: (row) => `$${row.limitedCPC}`,
            cell: (e, rowIndex) =>
                <input
                    onChange={(event) => changeLimitedCpcEvent(event, rowIndex)}
                    value={e.limitedCPC == undefined ? 0 : e.limitedCPC}
                    type="number"
                    className="cal"
                    inputMode="numeric"
                />
        },
        {
            name: "New Bid Adjustment",
            selector: (row) => row.newbidAdjustment,
            cell: (e) => {
                if (e.newbidAdjustment === undefined || e.newbidAdjustment === "undefined") {
                    return '0%'
                }
                else {
                    return `${e.newbidAdjustment}%`
                }
            }
        },
        {
            name: "Impressions",
            selector: (row) => row.impressions,

        },
        {
            name: "Clicks",
            selector: (row) => row.clicks,

        },
        {
            name: "CTR%",
            selector: (row) => row.ctr,
            cell: (e) => `${e.ctr}%`
        },
        {
            name: "Spend",
            selector: (row) => row.spend,
            cell: (e) => `$${e.spend}`
        },
        {
            name: "Sales",
            selector: (row) => row.sales,
            cell: (e) => `$${e.sales}`
        },
        {
            name: "Orders",
            selector: (row) => row.orders,
        },
        {
            name: "Units",
            selector: (row) => row.units,
        },
        {
            name: "CVR%",
            selector: (row) => row.cvr,
            cell: (e) =>`${e.cvr}%`
        },
        {
            name: "ACOS%",
            selector: (row) => row.acos,
            cell: (e) => `${e.acos}%`
        },
        {
            name: "CPC",
            selector: (row) => row.cpc,
            cell: (e) => `$${e.cpc}`
        },
        {
            name: "ROAS",
            selector: (row) => row.roas,
        },
    ]
};

export default useExpandColumns;
