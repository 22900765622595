import {
    useForm,
    yupResolver
} from "../../../helper/links/link";
import React, {useState} from "react";
import {loginSchema} from "../../../utility/validator";
import EmailController from "./EmailController";
import PasswordController from "./PasswordController";
import {formValue} from "../types/login";
import LoginButton from "../ui/LoginButton";
import RememberMe from "./RememberMe";
import ApiAuth from "../../../api-wrapper/auth/ApiAuth";
import {useNavigate} from "react-router-dom";
import {message} from "antd"
import axios from "axios";

const LoginForm = () => {
    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<formValue>({ resolver: yupResolver(loginSchema) });

    const [formValue, setFormValue] = useState<formValue>({
        email: '',
        password: ''
    })

    const navigate = useNavigate()

    const submitHandler = (data) => {
        setFormValue(data)
        ApiAuth.login(data)
            .then((res) => {
                if (axios.isAxiosError(res)) {
                    message.error(res.response?.data?.message)
                    return
                }
                if (res.status === 200) {
                    localStorage.setItem('sellerToken', res.data.accessToken)
                    localStorage.setItem('profileId', String(res.data.profileId))
                    localStorage.setItem('userName', res.data.customerName)
                    localStorage.setItem('antonTimezone', res.data.timezone)
                    navigate('/')
                    return
                }
                message.error("Internal server error")
            })
            .catch((err) => {
                console.log(err)
                message.error("Internal server error")
            });

    }

    return <div className="login-box">
        <h2 className="heading-login-box">Login</h2>
        <form onSubmit={handleSubmit(submitHandler)}>
            <EmailController
                control={control}
                register={register}
                errors={errors}
            />
            <PasswordController
                control={control}
                register={register}
                errors={errors}
            />
            <RememberMe
                formValue={formValue}
                setFormValue={setFormValue}
                reset={reset}
            />
            <LoginButton/>
        </form>
    </div>
}

export default LoginForm
