import {Flex, Select} from "antd"
import style from "./styles/rtMultipleSelect.module.scss"
import React, {FC} from "react"

type options = {
    value: string,
    label: string
}

interface RtSelectProps {
    onChange: (value: string) => void,
    initialValue?: string,
    options: options[],
    placeholder: string
}

const RtMultipleSelect: FC<RtSelectProps> = ({onChange, initialValue, options, placeholder}) => {

    return <Flex vertical>
        <Select
            mode="multiple"
            placeholder={placeholder}
            defaultValue={initialValue}
            onChange={onChange}
            options={options}
            className={style.state_select}
        />
    </Flex>
}

export default RtMultipleSelect
