import React from "react";
import {useNavigate} from "react-router-dom";
import {routePath} from "../router/types";
import {Button} from "antd";

const NonManagedCampaign: React.FC = () => {
    const navigate = useNavigate()

    function handleClick() {
        navigate(routePath.NON_MANAGED_CAMPAIGN)
    }

    return (
        <Button onClick={handleClick}>
            Non managed campaign
        </Button>
    )
}

export default NonManagedCampaign;
