import {AxiosResponse} from "axios";
import {ISponsoredProducts} from "../types/sponsoredProducts";
import {IChildData} from "../types/childDataTable";
import $api from "../../../axios";
import {changeCampaignStatusBody} from "../../../types/customers";

const api = `/placement/`

export const ApiPlacementList = async (data) => {
    const result = await $api.post<ISponsoredProducts, AxiosResponse<ISponsoredProducts>>(`${api}getPlacement`, data)
    return result.data
}

export const ApiChild = async (data) => {
    const result = await $api.post<IChildData, AxiosResponse<IChildData>>(`${api}getChildData`, data)
    return result.data
}

export const changeCampaignStatus = async (data: changeCampaignStatusBody) => {
    await $api.put(`${api}updateCampaignsState`, data)
}
