import {campaignStates, campaignStatesFilter, matchTypesEnum} from "../../types/customers";
import {MATCH_TYPES} from "../../modules/sponsoredProducts/constants";

export function matchByStateCampaign (filterCampaignState: campaignStatesFilter, campaignStatus: campaignStates): boolean {
    switch (filterCampaignState) {
        case campaignStatesFilter.ALL:
            return true
        case campaignStatesFilter.ENABLED:
            return campaignStatus === campaignStates.ENABLED
        case campaignStatesFilter.PAUSED:
            return campaignStatus === campaignStates.PAUSED
        case campaignStatesFilter.ARCHIVED:
            return campaignStatus === campaignStates.ARCHIVED
        case campaignStatesFilter.ALL_BUT_ARCHIVE:
            return campaignStatus === campaignStates.ENABLED
                || campaignStatus === campaignStates.PAUSED
        default:
            return false
    }
}

export function matchByMatchType (matchType: matchTypesEnum, campaignName: string): boolean {
    if (matchType === matchTypesEnum.ALL_NON_RANKING) {
        return !campaignName.includes(MATCH_TYPES.RANKING)
    }
    if (matchType === matchTypesEnum.ALL) {
        return true
    }

    return campaignName.includes(matchType)
}
