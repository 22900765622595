import ExpendAllButton from "../../../ui/ExpendAllButton";
import DataRepresentation from "../ui/DataRepresentation";
import React from "react";
import SearchInput from "../ui/SearchInput";
import SelectForm from "../ui/Select";
import NonManagedCampaign from "../../../components/NonManagedCampaign";
import {Flex, Space} from "antd"
import {setStateType} from "../types/setStateType";
import {filters} from "../types/placement";
import CampaignStateSelect from "../../../components/CampaignStateSelect/CampaignStateSelect";
import {pageNameEnum} from "../../../types/page";
import MatchTypesSelect from "../../../components/MatchTypesSelect/MatchTypesSelect";
import {matchTypesEnum} from "../../../types/customers";
import CampaignActions from "./CampaignActions";

interface DisplaySettingsProps {
    setFilters: setStateType<filters>
    filters: filters,
}

const DisplaySettings: React.FC<DisplaySettingsProps> = ({setFilters, filters}) => {

    const MATCH_TYPES = [
        matchTypesEnum.ALL,
        matchTypesEnum.RANKING,
        matchTypesEnum.EXACT,
        matchTypesEnum.PHRASE,
        matchTypesEnum.BROAD,
        matchTypesEnum.ASIN,
        matchTypesEnum.AUTO,
        matchTypesEnum.ALL_NON_RANKING
    ]

    return (
        <>
            <Flex
                vertical={false}
                justify={"space-between"}
                className={"my-2"}
            >
                <Space>
                    <ExpendAllButton/>
                    <DataRepresentation/>
                </Space>

                <Space>
                    <SelectForm/>
                    <SearchInput setFilters={setFilters}/>
                </Space>
            </Flex>
            <Flex
                vertical={false}
                justify={"space-between"}
            >
                <Space align={"start"}>
                    <NonManagedCampaign/>
                    <CampaignStateSelect
                        pageName={pageNameEnum.SPONSORED_PRODUCTS}
                        setFilters={setFilters}
                        initialValue={filters.campaignState}
                    />
                    <MatchTypesSelect
                        setFilters={setFilters}
                        options={MATCH_TYPES}
                        pageName={pageNameEnum.SPONSORED_PRODUCTS}
                        initialValue={filters.matchType}
                        key={filters.matchType}
                    />
                </Space>
                <Space align={"start"}>
                    <CampaignActions/>
                </Space>
            </Flex>
        </>
    )
}

export default DisplaySettings;
