
export enum weekFilterEnum {
    DAY = 0,
    WEEK = 1,
    MONTH = 2
}

export type weekFilter = weekFilterEnum

export enum sortEnum {
    ASC = 1,
    DESC = -1
}

export type sortType = sortEnum

export enum campaignsTypeEnum {
    SPONSORED_PRODUCTS = "Sponsored Products",
    SPONSORED_DISPLAY = "Sponsored Display",
    SPONSORED_BRANDS = "Sponsored Brands"
}

export enum placementsEnum {
    TOP_OF_SEARCH = 'Top of Search',
    REST_OF_SEARCH = 'Rest of Search',
    PRODUCT_PAGE = "Product Page"
}
