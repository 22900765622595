const nav = [
    {
        id: '1',
        name: "Sponsored Products",
        icon: <i className="fa fa-tachometer"></i>,
        path: '/'
    },
    {
        id: '2',
        name: "Sponsored Brands",
        icon: <i className="fa fa-circle"></i>,
        path: '/sponsored-brand'
    },
    {
        id: '3',
        name: "Sponsored Display",
        icon: <i className="fa fa-window-maximize"></i>,
        path: '/sponsored-display'
    },
    {
        id: "7",
        name: "History (SP)",
        icon: <i class="fa fa-regular fa-file-lines "></i>,
        path: '/advertising/history',
    },
    {
        id: "8",
        name: "History (SB/SD)",
        icon: <i class="fa fa-regular fa-file-lines "></i>,
        path: '/sb-sd-history',
    },
    {
        id: '3',
        name: "Filter",
        icon: <i class="fa  fa-solid fa-filter"></i>,
        path: '/filter'
    },
    {
        id: '5',
        name: "Settings",
        icon: <i class="fa fa-solid fa-gear"></i>,
        path: '',
        submenu: [
            {
                id: "1",
                name: "ACOS Filter",
                icon: <i class="fa fa-regular fa-file-lines "></i>,
                path: '/settings/acos'
            },
            {
                id: "2",
                name: "Other Filter",
                icon: <i class="fa fa-solid fa-boxes-stacked "></i>,
                path: '/settings/other'
            }
        ]
    },
    {
        id: '5',
        name: "Synchronize",
        icon: <i class="fa fa-solid fa-rotate"></i>,
        path: '/synchronize'
    },
]

export default nav
