import React from "react";
import {Flex, Select, Typography} from "antd";
import style from "./MatchTypesSelect.module.scss";
import {setStateType} from "../../modules/sponsoredProducts/types/setStateType";
import {customerFilters, matchTypesEnum} from "../../types/customers";
import {saveFilter} from "../../api-wrapper/campaign/ApiFilter";
import {pageNameEnum} from "../../types/page";

interface MatchTypesSelectProps {
    setFilters: setStateType<any>,
    options: matchTypesEnum[],
    pageName: pageNameEnum,
    initialValue: matchTypesEnum
}

const MatchTypesSelect: React.FC<MatchTypesSelectProps> = ({setFilters, options, pageName, initialValue}) => {
    const {Text} = Typography

    async function changeMatchType (matchType: string) {
        setFilters((filters) => {
            return {
                ...filters,
                matchType
            }
        })

        await saveFilter({
            type: customerFilters.MATCH_TYPE,
            pageName: pageName,
            value: matchType
        })
    }

    return (
        <Flex vertical>
            <Select
                className={style.state_select}
                onChange={changeMatchType}
                defaultValue={initialValue}
            >
                {
                    options.map((campaignState) => (
                        <Select.Option value={campaignState}>{campaignState}</Select.Option>
                    ))
                }
            </Select>
            <Text type="secondary">
                Match type
            </Text>
        </Flex>
    )
}

export default MatchTypesSelect;
