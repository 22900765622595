import React from "react";
import {LoadingOutlined} from "@ant-design/icons";
import {Spin} from "antd";
import style from "./fullPageLoader.module.scss"

const FullPageLoader: React.FC = () => {

    return <div className={style.full_page_loader}>
        <Spin
            size="large"
            indicator={
            <LoadingOutlined
                className={style.spin}
                spin
            />
        }
        />
    </div>
}

export default FullPageLoader
