import {useActions} from "../../../hooks/useActions";
import {useTypedSelector} from "../../../hooks/useTypedSelector";

const CheckFilterType = () => {
    const { setIsOrder } = useActions()
    const { isOrder} = useTypedSelector((select) => select.filter)

    return (
        <div className="form-check form-switch me-3">
            <input
                className="form-check-input"
                type="checkbox"
                name="isActive"
                onChange={(event) => setIsOrder(event.target.checked)}
                checked={isOrder}
                style={{width: "50px", height: "20px"}}
            />
        </div>
    )
}

export default CheckFilterType;
