import React, { useState, useEffect } from "react"
import DataTable from "react-data-table-component"
import {IPlacementData} from "../types/placement";

interface VirtualizedTableProps {
    data: IPlacementData[],
    columns: any[],
    rowHeight?: number,
    containerHeight?: number,
}

const VirtualizedTable: React.FC<VirtualizedTableProps> = (
    {
        data,
        columns,
        rowHeight = 50,
        containerHeight = 400
    }) => {
    const [visibleData, setVisibleData] = useState<IPlacementData[]>([])
    const [scrollPosition, setScrollPosition] = useState<number>(0)
    const totalRows = data.length

    const visibleStartIndex = Math.floor(scrollPosition / rowHeight);
    const visibleEndIndex = Math.min(
        Math.ceil((scrollPosition + containerHeight) / rowHeight),
        totalRows
    );

    useEffect(() => {
        setVisibleData(data.slice(visibleStartIndex, visibleEndIndex));
    }, [scrollPosition, data, visibleStartIndex, visibleEndIndex]);

    const handleScroll = (e) => {
        setScrollPosition(e.target.scrollTop);
    };

    const tableHeight = visibleData?.length >= 8 ? `${containerHeight}px` : visibleData?.length === 7 ? '350px' : visibleData?.length === 6 ? '300px' : visibleData?.length === 5 ? '250px' : visibleData?.length === 4 ? '200px' : visibleData?.length === 3 ? '150px' : visibleData?.length === 2 ? '100px' : visibleData?.length === 1 ? '50px' : '50px';

    return (
        <div
            style={{
                height: tableHeight,
                overflowY: "auto",
                position: "relative",
            }}
            onScroll={handleScroll}
        >
            <div style={{ height: `${totalRows * rowHeight}px`, position: "relative" }}>
                <div
                    style={{
                        position: "absolute",
                        top: `${visibleStartIndex * rowHeight}px`,
                        width: "100%",
                    }}
                >
                    <DataTable
                        className="table_content"
                        columns={columns}
                        data={visibleData}
                        noHeader
                    />
                </div>
            </div>
        </div >
    );
};

export default VirtualizedTable;
