
export enum customerFilters {
    "CAMPAIGN_STATE" = "campaignState",
    "MATCH_TYPE" = "matchType",
}

export enum matchTypesEnum {
    "ALL" = 'All',
    "RANKING" = 'Ranking',
    "EXACT" = 'Exact',
    "PHRASE" = 'Phrase',
    "BROAD" = 'Broad',
    "AUTO" = 'Auto',
    "ASIN" = 'ASIN',
    "ALL_NON_RANKING" = 'All non ranking'
}

export enum dataRepresentationEnum {
    "PLACEMENT" = 'Placement',
    "DATA_TABLE" = 'DataTable'
}

export enum campaignStates {
    "PAUSED" = 'PAUSED',
    "ENABLED" = 'ENABLED',
    "ARCHIVED" = 'ARCHIVED'
}

export enum campaignStatesFilter {
    "ALL" = "All",
    "ENABLED" = 'Enabled',
    "PAUSED" = 'Paused',
    "ARCHIVED" = 'Archived',
    "ALL_BUT_ARCHIVE" = 'All but archive'
}

export type stateByCampaignId = {
    campaignId: number,
    state: campaignStates
}

export type changeCampaignStatusBody = {
    stateByCampaignIds: stateByCampaignId[]
}
