import React, {FC, useState} from "react";
import {Switch} from "antd";
import {useActions} from "../../../hooks/useActions";
import {ICampaign} from "../types/sponsoredProducts";
import {campaignStates, stateByCampaignId} from "../../../types/customers";

interface SwitchCampaignState {
    campaign: ICampaign
}

const SwitchCampaignState: FC<SwitchCampaignState> = ({campaign}) => {
    const { fetchChangeCampaignsState } = useActions()

    function onChange(checked: boolean) {
        if (checked) {
            const statesByCampaignId: stateByCampaignId[] = [{
                campaignId: campaign.campaignId,
                state: campaignStates.ENABLED
            }]
            fetchChangeCampaignsState(statesByCampaignId)
            return
        }

        const statesByCampaignId: stateByCampaignId[] = [{
            campaignId: campaign.campaignId,
            state: campaignStates.PAUSED
        }]
        fetchChangeCampaignsState(statesByCampaignId)
    }

    return (
        <Switch
            checked={campaign.campaignStatus === campaignStates.ENABLED}
            onChange={onChange}
            size='small'
        />
    )
}

export default SwitchCampaignState
