import React from "react";
import {Flex, Select, Typography} from "antd";
import {filters} from "../../modules/sponsoredProducts/types/placement";
import {setStateType} from "../../modules/sponsoredProducts/types/setStateType";
import {pageNameEnum} from "../../types/page";
import {campaignStatesFilter, customerFilters} from "../../types/customers";
import style from "../CampaignStateSelect/CampaignStateSelect.module.scss"
import {saveFilter} from "../../api-wrapper/campaign/ApiFilter";

interface CampaignStateSelectProps {
    pageName: pageNameEnum,
    setFilters: setStateType<filters>,
    initialValue: campaignStatesFilter
}

const CampaignStateSelect: React.FC<CampaignStateSelectProps> = ({pageName, setFilters, initialValue}) => {
    const options = [ campaignStatesFilter.ALL, campaignStatesFilter.PAUSED, campaignStatesFilter.ENABLED ]
    const {Text} = Typography

    async function onChange(value: campaignStatesFilter) {
        setFilters((filters) => {
            return {
                ...filters,
                campaignState: value
            }
        })

        await saveFilter({
            type: customerFilters.CAMPAIGN_STATE,
            pageName: pageName,
            value: value
        })
    }

    return (
        <Flex vertical>
            <Select
                className={style.state_select}
                onChange={onChange}
                defaultValue={initialValue}
                key={initialValue}
            >
                {
                    options.map((campaignState) => (
                        <Select.Option value={campaignState}>{campaignState}</Select.Option>
                    ))
                }
            </Select>
            <Text type="secondary">
                Campaign state
            </Text>
        </Flex>
    )
}

export default CampaignStateSelect
