import './App.scss';
import DefaultLayout from './defaultLayout/DefaultLayout';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import Login from './pages/Login';
import routes from './router/routes'
import 'react-toastify/dist/ReactToastify.css';
import { UseContext } from './usecontext/useContext';

const App = () => {

  const RequireAuth = () => {
    let location = useLocation();
    const token = localStorage.getItem('sellerToken');

    if (!token) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return <Outlet />;
  }

  return (
    <>
      <ToastContainer />
      <UseContext>
        <BrowserRouter>
          <Routes>
            <Route
                exact
                path='/login'
                element={<Login/>}
            >
            </Route>
            {routes.map((pages) =>
                  <Route
                      exact
                      path='/'
                      element={<DefaultLayout />}
                      key={pages.path}
                  >
                    <Route path={pages.path} exact element={pages.component} />
                  </Route>
              )
            }
          </Routes>
        </BrowserRouter>
      </UseContext>
    </>
  );
}

export default App;
