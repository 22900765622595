import * as Yup from "yup";
const numeric = /^[0-9]+$/;
const decimal = /^[0-9]+(\.[0-9]+)?$/;
const email =
  /^[a-z0-9]([a-z0-9_\-\.]*)@([a-z0-9_\-\.]*)(\.[a-z]{2,4}(\.[a-z]{2}){0,2})$/i;

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .required("email is required")
    .matches(email, "Please check your email "),

  password: Yup.string().required("Password is required"),
});
