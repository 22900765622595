import React, {useCallback, useEffect, useMemo, useState} from "react";
import DataTable from "react-data-table-component";
import {
	ApiCampaignList,
	ApiUnmange,
	ApiChild,
	ApisaveInAmazon,
	changeCampaignState
} from "../../../api-wrapper/sb/apis";
import { useDispatch } from "react-redux";
import { childBidHandler } from "../../../redux/action";
import Toast from "../../../helper/toast/Toast";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import ExpandTable from "./ExpandTable";
import { ProfileContext } from "../../../usecontext/useContext";
import { useContext } from "react";
import NonManagedCampaign from "../../../components/NonManagedCampaign";
import FullPageLoader from "../../../components/FullPageLoader/FullPageLoader";
import {setCampaignData} from "../../../modules/sponsoredProducts/store/actionCreators/sponsoredProduct";
import SwitchCampaignState from "../../../components/SwitchCampaignState";
import CampaignStateSelect from "../../../components/CampaignStateSelect/CampaignStateSelect";
import {pageNameEnum} from "../../../types/page";
import {matchByMatchType, matchByStateCampaign} from "../../../helper/filter/filters.helpers";
import {campaignStatesFilter, matchTypesEnum} from "../../../types/customers";
import {Button, Card, Empty, Flex, message, Space} from "antd";
import MatchTypesSelect from "../../../components/MatchTypesSelect/MatchTypesSelect";
import {getFilters} from "../../../api-wrapper/campaign/ApiFilter";
import CampaignActions from "../../../components/CampaignActions";

const CampaignTable = ({ fromDate, toDate, }) => {
	const dispatch = useDispatch();
	const { orderSwitch, setDefaultParams,defaultParams, limitValue, setlimitValue } = useContext(ProfileContext);
	const [TOSPercentage, setTOSPercentage] = useState();
	const [defaultTargetAcos, setdefaultTargetAcos] = useState();
	const [campaignData, setcampaignData] = useState([]);
	const [subval, setsubval] = useState([]);
	const [campaignIds, setCampaignIds] = useState([]);
	const [campaignNamesArr, setCampaignNamesArr] = useState([]);
	const [aovData, setaovData] = useState([]);
	const [expandedRows, setExpandedRows] = useState([]);
	const CustomCollapsedIcon = () => <AiOutlinePlus />;
	const CustomExpandedIcon = () => <AiOutlineMinus />;
	const customExpandIcon = { collapsed: <CustomCollapsedIcon />, expanded: <CustomExpandedIcon /> };
	const [perPage, setPerPage] = useState(localStorage.getItem('perPage') || 25);
	const [totalRecords, settotalRecords] = useState(0);
	const [rowSelect, setRowSelect] = useState([]);
	const [selectALL, setSelectALL] = useState(false);
	const limitArray = [25, 100, 500, 1000];
	const paginationRowsPerPageOptions = [...limitArray, totalRecords];
	paginationRowsPerPageOptions.sort((a, b) => a - b);
	const [bestFlag, setbestFlag] = useState(false);
	const [bestArr, setBestArr] = useState([]);
	const [bestCVRCPC, setBestCVRCPC] = useState([]);
	const [defaultState, setDefaultState] = useState({
		campaignFilterName: '',
		searchEL: '',
		totalRecords: 0,
		selectALL: false,
		bestFlag: false,
		perPage: localStorage.getItem('perPage') || 25,
	});
	const [arg, setArg] = useState(
		{
			pageNo: 1,
			perPage: localStorage.getItem('perPage') || 25,
			searchType: defaultState.campaignFilterName,
			search: defaultState.searchEL
		});

	const [filters, setFilters] = useState({
		campaignState: campaignStatesFilter.ALL,
		matchType: matchTypesEnum.ALL
	})

	useEffect( () => {
		getFilters(pageNameEnum.SPONSORED_BRANDS)
			.then(filtersData => {
				setFilters((filters) => {
					const newFilters = { ...filters }
					filtersData.forEach((filter) => {
						newFilters[filter.type] = filter.value
					})

					return newFilters
				})
			})
			.catch((err) => {
				message.error('Error loading filters')
			})
	}, [])

	const columns = [
		{
			name: (
				<input
					type="checkbox"
					onChange={(e) => handleSelectAll(e)}
					checked={selectALL}
				/>
			),
			width: "50px",
			cell: (row) => (
				<input
					type="checkbox"
					checked={rowSelect?.includes(row.campaignId)}
					onChange={(e) => handleRowSelect(e, row.campaignId)}
				/>
			),
		},
		{
			name: "Target Acos",
			sortable: true,
			selector: (row) => row.targetAcos,
			cell: (e) => {
				return <>{`-`}</>;
			},
		},
		{
			name: "Active",
			cell: (row) => {
				return <SwitchCampaignState
					key={row.campaignStatus}
					campaign={row}
					campaignData={campaignData}
					setCampaignData={setCampaignData}
					changeCampaignState={changeCampaignState}
				/>
			}
		},
		{
			name: "Campaigns",
			selector: (row) => row.campaignName,
			sortable: true,
			cell: (e) => {
				return <>
					<div>{e.campaignName}</div>
				</>
			},
		},
		{
			name: "Target CPC In Amazon",
			sortable: false,
			width: "80px",
			selector: (row) => row.tagetCPCAmazon,
			cell: (e) => {
				return <>{`-`}</>;
			},
		},
		{
			name: "Target CPC",
			sortable: true,
			selector: (row) => row.targetCPC,
			cell: (e) => {
				return <>{`-`}</>;
			},
		},
		{
			name: "Best CPC",
			sortable: true,
			selector: (row) => row.bestCPC,
			cell: (e) => {
				return <>{`-`}</>;
			},
		},
		{
			name: "Limited CPC",

			sortable: true,
			selector: (row) => row.limitedCPC,
			cell: (e) => {
				return <>{`-`}</>;
			},
		},
		{
			name: "Impressions",
			selector: (row) => row.impressions,
			sortable: true,
		},
		{
			name: "Clicks",
			selector: (row) => row.clicks,
			sortable: true,
		},
		{
			name: "CTR%",
			sortable: true,
			selector: (row) => row.ctr,
			cell: (e) => `${e.ctr}%`,
		},
		{
			name: "Spend",
			sortable: true,
			selector: (row) => row.spend,
			cell: (e) => `$${e.spend}`,
		},
		{
			name: "Sales",
			sortable: true,
			selector: (row) => row.sales,
			cell: (e) => `$${e.sales}`,
		},
		{
			name: "Orders",
			selector: (row) => row.orders,
			sortable: true,
		},
		{
			name: "Units",
			selector: (row) => row.units,
			sortable: true,
		},
		{
			name: "CVR%",
			sortable: true,
			selector: (row) => row.cvr,
			cell: (e) => `${e.cvr}%`,
		},
		{
			name: "ACOS%",
			sortable: true,
			selector: (row) => row.acos,
			cell: (e) => `${e.acos}%`,
		},
		{
			name: "CPC",

			sortable: true,
			selector: (row) => row.cpc,
			cell: (e) => `$${e.cpc}`,
		},
		{
			name: "ROAS",
			selector: (row) => row.roas,
			sortable: true,
		},
	];

	useEffect(() => {
		localStorage.setItem('SBtarget', JSON.stringify([]));
	}, [])

	useEffect(() => {
		let getStorage = rowSelect;
		let check = campaignData.every(x => getStorage?.includes(x.campaignId));

		if (check) {
			setSelectALL(true);
		} else {
			setSelectALL(false);
		}

	}, [rowSelect, campaignData]);

	useEffect(() => {
		if (campaignIds?.length != 0) {
			childHandler()
		}
	}, [campaignIds, arg.type]);

	useEffect(() => {
		getCampaignData(arg)
	}, [fromDate, toDate, arg, orderSwitch]);

	const filteredCampaigns = useMemo(() => {
		return campaignData.filter((campaign) => {
			return matchByStateCampaign(filters.campaignState, campaign.campaignStatus)
			&& matchByMatchType(filters.matchType, campaign.campaignName)
		})
	}, [campaignData, filters])

	const handleRowSelect = (e, rowId) => {
		const isChecked = e.target.checked;
		if (isChecked) {
			if (!rowSelect?.includes(rowId)) {
				setRowSelect([...rowSelect, rowId]);
			}
		} else {
			const updatedSelectedRows = rowSelect.filter((id) => id !== rowId);
			setRowSelect(updatedSelectedRows);
		}
	};

	const handleSelectAll = (e) => {
		if (e.target.checked) {
			let ids = campaignData.map(x => x.campaignId)
			let prevSelect = rowSelect;
			let item = [...ids, ...prevSelect]
			let uniqueChars = [...new Set(item)];
			setRowSelect(uniqueChars)
			setSelectALL(true)
		}
		else {
			setSelectALL(false)
			let data = rowSelect || [];
			let aSet = new Set(campaignData.map(item => item.campaignId));
			let idsNotInA = data.filter(id => !aSet.has(id));
			setRowSelect(idsNotInA)
		}
	}

	const getCampaignData = async () => {
		const profileId = localStorage.getItem("profileId") || "";
		if (!fromDate || !toDate) return; // Exit early if dates are missing

		// Prepare base data
		let data = {
			profileId,
			isManage: true,
			fromDate,
			toDate,
			...arg,
			filterType: orderSwitch ? "Orders" : "Date",
		};

		if (!limitArray?.includes(parseInt(data.perPage))) {
			data.perPage = "ALL";
		}

		try {
			const response = await ApiCampaignList(data);

			if (response?.isSuccess) {
				const campaignsLength = response.campaignsResult?.length || 0;
				if (data.perPage === "ALL" || !limitArray?.includes(data.perPage) || response.totalRecords < data.perPage) {
					setPerPage(campaignsLength);
					localStorage.setItem("perPage", campaignsLength);
				}

				settotalRecords(response.totalRecords);
				setTOSPercentage(response.TOSPercentage);
				setcampaignData(response.campaignsResult);
				setExpandedRows([]);
				setCampaignIds([]);
				setDefaultParams({...defaultParams,defaultBid:response?.defaultBid,maxTargetCPC:response.maxTargetCPC,minTargetCPC:response.minTargetCPC,orderVal:response.defaultOrder||''})
				setlimitValue(response.limitedCPCValue?.conditions);
				setaovData(response.getAOVData);
				setdefaultTargetAcos(response.defaultTargetAcos);
				// setOrderVal(response.defaultOrder || "");
			} else {
				Toast.error(response?.message || "Failed to fetch campaign data.");
			}
		} catch (error) {
			Toast.error("Something went wrong");
		}
	};

	const ExpandedComponent = useCallback(
		({ data }) => {

			return (
				<div
					className="sub_table campaign_table campaign_sub sb_sub"
					key={data.campaignId}
				>
					<ExpandTable
						arg={arg}
						setArg={setArg}
						campaignName={data?.campaignName}
						placementData={data?.targetData}
						campaignData={campaignData}
						setsubval={setsubval}
						aovData={aovData}
						order={data?.orders}
						defaultParams={defaultParams}
						limitValue={limitValue}
						setcampaignData={setcampaignData}
						TOSPercentage={TOSPercentage}
						defaultTargetAcos={defaultTargetAcos}
						setRowSelect={setRowSelect}
						rowSelect={rowSelect}
						setbestFlag={setbestFlag}
						bestFlag={bestFlag}
						bestArr={bestArr}
						handleSelectTarget={handleSelectTarget}
						bestCVRCPC={bestCVRCPC}
					/>
				</div>
			);
		},
		[campaignData, bestArr, bestCVRCPC]
	);


	const childHandler = () => {
		const profileId = localStorage.getItem("profileId") || "";
		let data = {
			profileId: profileId,
			campaignIds: campaignNamesArr,
			fromDate: fromDate,
			toDate: toDate,
		}

		if (orderSwitch) {
			data = {
				...data,
				filterType: "Orders"
			}
		}
		else {
			data = {
				...data,
				filterType: "Date"
			}
		}
		ApiChild(data)
			.then(async (res) => {
				let oldData = campaignData
				// if (res.isSuccess) {
				// 	dispatch(childBidHandler(subval))
				// 	setsubval([...subval])
				// 	if (res?.targetSubResult.length != 0) {
				// 		oldData.map(item => {
				// 			res?.targetSubResult?.find(el => {
				// 				if (el.campaignId == item.campaignId) {
				// 					item.targetData = el.targetData
				// 				}
				// 			})

				// 		})

				// 	}
				// 	setcampaignData(oldData)

				// }
				if (res.isSuccess) {
					dispatch(childBidHandler(subval))
					setsubval([...subval])
					if (res?.targetSubResult.length != 0) {
						oldData.map(item => {
							res?.targetSubResult?.find(el => {
								if (el.campaignId == item.campaignId) {
									item.targetData = el.targetData
								}
							})
						})
					}
					setcampaignData(oldData)
				}
			}).catch((err) => {
				Toast.error("Something went wrong");
			});
	}

	const handleRowExpandToggle = (expanded, row) => {
		if (expanded) {
			setExpandedRows([...expandedRows, row]);
			setCampaignIds([...campaignIds, row.campaignName])
			setCampaignNamesArr([...campaignNamesArr, row.campaignId])
			localStorage.removeItem('SBtarget')
		} else {
			setExpandedRows(
				expandedRows.filter((id) => id.campaignName !== row.campaignName)
			);
			setCampaignIds(campaignIds.filter((id) => id !== row.campaignName))
			setCampaignNamesArr(campaignNamesArr.filter((id) => id !== row.campaignId))
		}
	};

	const handleExpandAllClick = () => {
		if (expandedRows.length === 0) {
			const allRowIds = campaignData.map((row) => row.campaignName);
			setCampaignIds(allRowIds)
			setExpandedRows(campaignData);
			const compaignIds = campaignData.map((row) => row.campaignId)
			setCampaignNamesArr(compaignIds)
			localStorage.setItem('selectedItem', compaignIds)
			localStorage.removeItem('currentDataId')
		} else {
			localStorage.setItem('SBtarget', JSON.stringify([]));
			setExpandedRows([]);
			setCampaignIds([])
			setCampaignNamesArr([])
		}
	};

	// -------- weekly table data --------------//

	// ---pagination ---//
	const handlePageChange = (page) => {
		setExpandedRows([])
		setArg({
			...arg,
			pageNo: page,
			perPage: perPage
		})
		setSelectALL(false)
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		setArg({
			...arg,
			pageNo: page,
			perPage: newPerPage
		})
		localStorage.setItem('perPage', newPerPage);
		setPerPage(newPerPage)
		setExpandedRows([]);
	};


	const handleSort = (column, sortDirection) => {
		const fieldMapping = {
			"Target CPC": "targetCPC",
			"Bid": "bid",
			"Campaigns": "campaignName",
			"Best CPC": "bestCPC",
			"Limited CPC": "limitedCPC",
			"Impressions": "impressions",
			"Clicks": "clicks",
			"CTR%": "ctr",
			"Spend": "spend",
			"Sales": "sales",
			"Orders": "orders",
			"Units": "units",
			"CVR%": "cvr",
			"ACOS%": "acos",
			"CPC": "cpc",
			"ROAS": "roas",
		};

		const field = fieldMapping[column.name] || "";
		const sorting = sortDirection === "asc" ? "1" : "-1";
		setArg({ fieldName: field, sortType: sorting });
	};

	const HandleManageCampaign = () => {
		const profileId = localStorage.getItem("profileId") || "";
		let filterData = rowSelect;

		let data = {
			isManage: false,
			campaignIds: filterData,
			profileId: profileId,
			type: "Sponsored Brands"
		};

		ApiUnmange(data)
			.then((res) => {
				if (res.isSuccess) {
					Toast.success(res.message);
					getCampaignData()
				}
			}).catch((err) => {
				Toast.error("somthing went wrong!!");
			});
	}

	const handleApplyBestCpc = () => {
		setBestCVRCPC([])
		let oldCampaign = campaignData;
		let getId = rowSelect;
		let findPlacement = oldCampaign.filter(campaign => getId?.includes(campaign.campaignId) && campaign?.targetData);
		setBestArr(findPlacement.map(x => x.campaignId))
	};

	const handleSelectTarget = (e, rowId, row) => {

		const isChecked = e.target.checked;
		let arr = JSON.parse(localStorage.getItem('SBtarget')) || [];

		if (isChecked) {
			if (!arr?.includes(rowId)) {
				arr.push(rowId);
				localStorage.setItem('SBtarget', JSON.stringify(arr));
			}
		} else {
			const data = arr.filter((data) => data !== rowId);

			localStorage.setItem('SBtarget', JSON.stringify(data));
		}

	};

	const handelApplyOnAmazon = () => {
		const profileId = localStorage.getItem("profileId") || "";

		let sendArr = []
		let arr = JSON.parse(localStorage.getItem('SBtarget')) || [];
		let campaign = campaignData.filter(x => x.targetData)
		let target = campaign.map(item => item.targetData || []).flat();

		let filteredData = target?.filter(x => arr?.includes(x.targetId)) || [];

		if (filteredData?.length == 0) {
			Toast.error("Target ACOS is not selected !!");
		}
		else {
			sendArr = filteredData?.map(item => ({
				targetId: item.targetId,
				adGroupId: item.adGroupId,
				bid: Number(item.limitedCPC),
				targetName: item.targetName
			}));

			let data = {
				targetData: sendArr,
				profileId: profileId,
				applyOn: "Amazon"
			};
			ApisaveInAmazon(data)
				.then((res) => {
					if (res.isSuccess) {
						Toast.success(res.message);
						getCampaignData()
					}
				}).catch((err) => {
					Toast.error("somthing went wrong!!");
				});
		}
	}

	const handleSearch = () => {
		setCampaignNamesArr([]);
		setRowSelect([]);
		localStorage.setItem('SBtarget', JSON.stringify([]));
		setArg({ ...arg, search: defaultState.searchEL })
	}

	const handleOtherFilter = (el) => {
		setRowSelect([]);
		setCampaignNamesArr([]);
		localStorage.setItem('SBtarget', JSON.stringify([]));
		setDefaultState({ ...defaultState, campaignFilterName: el.value })
		setArg({ ...arg, searchType: el.value, perPage: perPage, pageNo: 1 })
	}

	const MATCH_TYPES = [
		matchTypesEnum.ALL,
		matchTypesEnum.RANKING,
		matchTypesEnum.EXACT,
		matchTypesEnum.PHRASE,
		matchTypesEnum.BROAD,
		matchTypesEnum.ASIN,
	]
	return (
		<>
			<Card>
				<div className="data_table campaign_table sb_table scrollable_table">
					<div className="campaign_btn main_campaign_res d-flex justify-content-between">
						<div className="d-flex">
							<button onClick={handleExpandAllClick} className="expand-btn w-100">
								{expandedRows?.length === campaignData?.length
									? "Collapse All"
									: "Expand All"}
							</button>
							<div className="search-btn mx-2">
								<div className="inner-search-btn">
									<input
										type="search"
										className="form-control"
										placeholder="Search Campaign"
										onChange={(e) => setDefaultState({...defaultState, searchEL: e.target.value})}
									/>
									<button className="btn btn-primary" onClick={handleSearch}>
										<i className="fas fa-search"></i>
									</button>
								</div>
							</div>
						</div>
					</div>
					<Flex
						vertical={false}
						justify={"space-between"}
						className={"my-2"}
					>
						<Space align={"start"}>
							<NonManagedCampaign/>
							<CampaignStateSelect
								pageName={pageNameEnum.SPONSORED_BRANDS}
								setFilters={setFilters}
								initialValue={filters.campaignState}
							/>
							<MatchTypesSelect
								setFilters={setFilters}
								options={MATCH_TYPES}
								pageName={pageNameEnum.SPONSORED_BRANDS}
								initialValue={filters.matchType}
								key={filters.matchType}
							/>
						</Space>
						<Space vertical={false}>
							<CampaignActions
								selectedCampaignIds={rowSelect}
								setSelectedCampaignIds={setRowSelect}
								changeCampaignState={changeCampaignState}
								setCampaignData={setcampaignData}
								campaignData={campaignData}
							/>
							<Button onClick={() => handleApplyBestCpc()}>Apply Best
								CPC
							</Button>
							<Button onClick={() => HandleManageCampaign()}>Manage
								Campaign
							</Button>
							<Button onClick={() => handelApplyOnAmazon()}>Apply On
								Amazon
							</Button>
						</Space>
					</Flex>

					<DataTable
						title=""
						columns={columns}
						data={filteredCampaigns}
						onSort={handleSort}
						key={perPage}
						expandableRows
						expandableRowsComponent={ExpandedComponent}
						expandableIcon={customExpandIcon}
						onRowExpandToggled={handleRowExpandToggle}
						isRowExpandable
						pagination
						expandableRowExpanded={(row) => {
							return campaignIds?.includes(row?.campaignName)
						}}
						paginationTotalRows={totalRecords}
						paginationPerPage={perPage}
						paginationRowsPerPageOptions={paginationRowsPerPageOptions}
						paginationServer
						onChangePage={handlePageChange}
						onChangeRowsPerPage={handlePerRowsChange}
						keyField="campaignId"
						noDataComponent={<Empty/>}
					/>
				</div>
			</Card>
			{
				campaignData.length === 0 &&
				<FullPageLoader/>
			}
		</>
	);
}


export default CampaignTable;
