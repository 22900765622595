
export enum UserActionTypes {
    FETCH_DEFAULT_ORDER_SUCCESS = "FETCH_DEFAULT_ORDER_SUCCESS",
    FETCH_DEFAULT_ORDER_ERROR = "FETCH_DEFAULT_ORDER_ERROR",
    FETCH_DEFAULT_ORDER = "FETCH_DEFAULT_ORDER",
    SET_DEFAULT_ORDER = "SET_DEFAULT_ORDER",
    SET_IS_ORDER = "SET_IS_ORDER",
    SET_DATE_RANGE = "SET_DATE_RANGE"
}

interface FetchDefaultOrder {
    type: UserActionTypes.FETCH_DEFAULT_ORDER,
}

interface FetchDefaultOrderSuccess {
    type: UserActionTypes.FETCH_DEFAULT_ORDER_SUCCESS,
    payload: number
}

interface FetchDefaultOrderError {
    type: UserActionTypes.FETCH_DEFAULT_ORDER_ERROR,
    payload: string
}

interface SetDefaultOrder {
    type: UserActionTypes.SET_DEFAULT_ORDER,
    payload: number
}

interface SetIsOrderAction {
    type: UserActionTypes.SET_IS_ORDER,
    payload: boolean
}

interface SetDateRange {
    type: UserActionTypes.SET_DATE_RANGE,
    payload: {
        from: string,
        to: string
    }
}

export type FilterAction =
    SetIsOrderAction |
    SetDateRange |
    SetDefaultOrder |
    FetchDefaultOrder |
    FetchDefaultOrderSuccess |
    FetchDefaultOrderError

export interface FilterState {
    dateRange: {
        from: string,
        to: string
    },
    isOrder: boolean,
    defaultOrder: number,
    loaded: boolean,
    error: string | null
}
