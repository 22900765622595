import {ICampaign, IDefaultTargetAcos} from "../../types/sponsoredProducts";
import {stateByCampaignId} from "../../../../types/customers";

export enum SponsoredProductActionType {
    FETCH_SPONSORED_PRODUCTS = "FETCH_SPONSORED_PRODUCTS",
    FETCH_SPONSORED_PRODUCTS_SUCCESS = "FETCH_SPONSORED_PRODUCTS_SUCCESS",
    FETCH_SPONSORED_PRODUCTS_ERROR = "FETCH_SPONSORED_PRODUCTS_ERROR",
    SET_CAMPAIGN_DATA = "SET_CAMPAIGN_DATA",
    CHANGE_CAMPAIGN = "CHANGE_CAMPAIGN",
    FETCH_CAMPAIGN_STATE_SUCCESS = "FETCH_CAMPAIGN_STATE_SUCCESS",
    FETCH_CAMPAIGN_STATE_ERROR = "FETCH_CAMPAIGN_STATE_ERROR"
}

interface FetchSponsoredProducts {
    type: SponsoredProductActionType.FETCH_SPONSORED_PRODUCTS,
}

interface FetchSponsoredProductsSuccess {
    type: SponsoredProductActionType.FETCH_SPONSORED_PRODUCTS_SUCCESS,
    payload: campaignData
}

interface FetchSponsoredProductsError {
    type: SponsoredProductActionType.FETCH_SPONSORED_PRODUCTS_ERROR,
    payload: string
}

interface ChangeCampaign {
    type: SponsoredProductActionType.CHANGE_CAMPAIGN,
    payload: ICampaign
}

interface SetCampaignData {
    type: SponsoredProductActionType.SET_CAMPAIGN_DATA,
    payload: any[]
}

interface FetchCampaignStateSuccess {
    type: SponsoredProductActionType.FETCH_CAMPAIGN_STATE_SUCCESS,
    payload: stateByCampaignId[]
}

interface FetchCampaignStateError {
    type: SponsoredProductActionType.FETCH_CAMPAIGN_STATE_ERROR,
    payload: string
}

type getAOVData = {
    _id: string,
    campaignName: string,
    value: number,
    COGS: number
}

type targetAcos = {
    TOS_ACOS: string,
    PP_ACOS: string,
    ROS_ACOS: string,
}

export type SponsoredProductAction =
    FetchSponsoredProducts |
    FetchSponsoredProductsSuccess |
    FetchSponsoredProductsError |
    SetCampaignData |
    ChangeCampaign |
    FetchCampaignStateSuccess |
    FetchCampaignStateError

interface IConditions {
    decreaseBid: number,
    from: number,
    increaseBid: number,
    to: number,
    _id: number,
}

export type campaignData = {
    campaigns: ICampaign[],
    defaultTargetAcos: IDefaultTargetAcos[],
    limitedCPCValue: {
        conditions: IConditions[]
    },
    targetAcos: targetAcos[],
    getAOVData: getAOVData[],
    TOSPercentage: number,
    currentPageNo: number,
    defaultBid: number,
    defaultOrder: number,
    limitValue: number,
    maxTargetCPC: number,
    minTargetCPC: number,
    totalPages: number,
    totalRecords: number,
}

export interface SponsoredProductState {
    campaignData: campaignData,
    loaded: boolean,
    error: null | string,
}
