import {AxiosResponse} from "axios";
import {IDefaultOrder} from "../../types/settings";
import $api from "../../axios";

const api = `/setting/`
const apiAOV = `/averageAOV/`

const ApiCreateTargetCPC = (data) => {
    return $api.post(`${api}create`, data)
        .then(res => res.data).catch(res => res.data)
}
const ApiSaveDefaultBid = (data) => {
    return $api.post(`${api}saveDefaultBid`, data)
        .then(res => res.data).catch(res => res.data)
}
const ApiGetTargetCPC = (data) => {
    return $api.post(`${api}get`, data)
        .then(res => res.data).catch(res => res.data)
}
const ApiGetAverageAOV = (data) => {
    return $api.post(`${apiAOV}getAllAOV`, data)
        .then(res => res.data).catch(res => res.data)
}
const ApiCreateAverageAOV = (data) => {
    return $api.post(`${apiAOV}createAOV`, data)
        .then(res => res.data).catch(res => res.data)
}
const ApiUpdateAverageAOV = (id, data) => {
    return $api.post(`${apiAOV}updateAOV/${id}`, data)
        .then(res => res.data).catch(res => res.data)
}
const ApiDeleteAverageAOV = (data) => {
    return $api.delete(`${apiAOV}deleteAOV/${data}`)
        .then(res => res.data).catch(res => res.data)
}
const ApiSaveAcos = (data) => {
    return $api.post(`${api}defaultTargetAcos/saveAcos`, data)
        .then(res => res.data).catch(res => res.data)
}
const ApiSaveOtherAcos = (data) => {
    return $api.post(`${api}defaultTargetAcos/saveAcosSBSD`, data)
        .then(res => res.data).catch(res => res.data)
}

const ApiGetAcos = (data) => {
    return $api.post(`${api}defaultTargetAcos/getAcos`, data)
        .then(res => res.data).catch(res => res.data)
}

const ApiTopModifier = (data) => {
    return $api.post(`${api}TOSPercentage`, data)
        .then(res => res.data).catch(res => res.data)
}

const ApiAdjustStatus = (data) => {
    return $api.post(`${api}excludeAdjustedCampaigns`, data)
        .then(res => res.data).catch(res => res.data)
}

const ApiLimitSave = (data) => {
    return $api.post(`${api}createLimitedCPC`, data)
        .then(res => res.data).catch(res => res.data)
}

const getDefaultOrder = async (data)=> {
    const response = await $api.post<IDefaultOrder, AxiosResponse<IDefaultOrder>>(`${api}getDefaultOrder`, data)
    return response.data
}

export {
    ApiLimitSave,
    ApiSaveOtherAcos,
    ApiAdjustStatus,
    ApiTopModifier,
    ApiGetAcos,
    ApiSaveAcos,
    ApiCreateTargetCPC,
    ApiGetTargetCPC,
    ApiGetAverageAOV,
    ApiCreateAverageAOV,
    ApiUpdateAverageAOV,
    ApiDeleteAverageAOV,
    ApiSaveDefaultBid,
    getDefaultOrder
}

