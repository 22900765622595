import {
    DisplaySettingAction,
    DisplaySettingsActionType,
    DisplaySettingsState,
} from "../types/displaySettings";
import {dataRepresentationEnum, matchTypesEnum} from "../../../../types/customers";

const initialState: DisplaySettingsState = {
    selectedOptions: [],
    bestArr: [],
    fieldName: "",
    sortType: 1,
    selectedCampaignIds: [],
    expandableCampaignIds: [],
    dataRepresentationType: dataRepresentationEnum.PLACEMENT,
    perPage: 0,
    pageNo: 0
}

export const displaySettingsSpReducer = (state = initialState, action: DisplaySettingAction): DisplaySettingsState => {
    switch (action.type) {
        case DisplaySettingsActionType.SET_SELECTED_OPTIONS:
            return {
                ...state,
                selectedOptions: action.payload
            }
        case DisplaySettingsActionType.SET_BEST_ARR:
            return {
                ...state,
                bestArr: action.payload
            }
        case DisplaySettingsActionType.SET_FIELD_NAME:
            return {
                ...state,
                fieldName: action.payload
            }
        case DisplaySettingsActionType.SET_SORT_TYPE:
            return {
                ...state,
                sortType: action.payload
            }
        case DisplaySettingsActionType.SET_SELECTED_CAMPAIGN_IDS:
            return {
                ...state,
                selectedCampaignIds: action.payload
            }
        case DisplaySettingsActionType.SET_EXPANDABLE_CAMPAIGN_IDS:
            return {
                ...state,
                expandableCampaignIds: action.payload
            }
        case DisplaySettingsActionType.SET_DATA_REPRESENTATION:
            return {
                ...state,
                dataRepresentationType: action.payload
            }
        case DisplaySettingsActionType.SET_PER_PAGE:
            return {
                ...state,
                perPage: action.payload
            }
        case DisplaySettingsActionType.SET_PAGE_NO:
            return {
                ...state,
                pageNo: action.payload
            }
        default:
            return state
    }
}
