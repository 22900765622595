import {combineReducers} from "redux";
import {filterReducer} from "./filterReducer";
import usersReducer from "../reducer";
import {sponsoredProductReducer} from "../../modules/sponsoredProducts";
import {displaySettingsSpReducer} from "../../modules/sponsoredProducts";

export const rootReducer = combineReducers({
    filter: filterReducer,
    sponsoredProduct: sponsoredProductReducer,
    displaySettingsSp: displaySettingsSpReducer,
    data: usersReducer,
})

export type RootState = ReturnType<typeof rootReducer>
