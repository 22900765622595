import DataTable from "react-data-table-component";
import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import React, {FC, useCallback, useEffect, useMemo} from "react";
import {useHelperFunctions} from "../hooks/useHelperFunctions";
import CampaignColumns from "./CampaignColumns";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useActions} from "../../../hooks/useActions";
import {MATCH_TYPES} from "../constants";
import {sortEnum, sortType} from "../types/types";
import ExpandTable from "./ExpandTable";
import FullPageLoader from "../../../components/FullPageLoader/FullPageLoader";
import {filters} from "../types/placement";
import {ICampaign} from "../types/sponsoredProducts";
import {matchByMatchType, matchByStateCampaign} from "../../../helper/filter/filters.helpers";
import {matchTypesEnum} from "../../../types/customers";
import {Empty} from "antd";

interface TableProps {
    filters: filters;
}

const Table: FC<TableProps> = ({filters}) => {
    const { childHandler } = useHelperFunctions()
    const { campaignData, loaded} = useTypedSelector((select) => select.sponsoredProduct)
    const { expandableCampaignIds, selectedCampaignIds, bestArr, perPage} = useTypedSelector((select) => select.displaySettingsSp)
    const { setExpandableCampaignIds, setSelectedCampaignIds, setFieldName, setSortType, setPerPage, setPageNo } = useActions()
    const { getCampaignData } = useHelperFunctions()

    const campaigns = useMemo(() => {
        return campaignData.campaigns.filter((campaign) => {
            return matchByMatchType(filters.matchType, campaign.campaignName) &&
            matchByStateCampaign(filters.campaignState, campaign.campaignStatus) &&
            matchBySearchValue(campaign)
        })

    }, [filters, campaignData.campaigns]);

    function matchBySearchValue(campaign: ICampaign): boolean {
        if (!filters.searchValue) {
            return true
        }

        const campaignNameLowerCase = campaign.campaignName.toLowerCase()
        const SearchValueLoWerCase = filters.searchValue.toLowerCase()
        return campaignNameLowerCase.includes(SearchValueLoWerCase)
    }

    const paginationRowsPerPageOptions = () => {
        return [campaigns.length, 25, 100, 500, 1000]
    }

    useEffect(() => {
        setPerPage(campaigns.length)
    }, [campaigns]);

    useEffect(() => {
        const arr: number[] = [ ...selectedCampaignIds ]
        campaigns.forEach((crrObj) => {
            crrObj.placementsData?.forEach((placement) => {
                if (placement.clicks > 4 && (placement.limitedCPC != placement.tagetCPCAmazon)) {
                    if (!arr.includes(crrObj.campaignId) && expandableCampaignIds.includes(crrObj.campaignId)) {
                        arr.push(crrObj.campaignId)
                    }
                }
            })
        })

        setSelectedCampaignIds(arr)
    }, [campaigns])

    const handleRowExpandToggle = (expanded, row) => {
        if (expanded) {
            setExpandableCampaignIds([...expandableCampaignIds, row.campaignId])
            childHandler([ row.campaignId ])
        } else {
            setExpandableCampaignIds(
                expandableCampaignIds.filter((campaignId) => campaignId !== row.campaignId)
            );
            setSelectedCampaignIds(selectedCampaignIds.filter((id) => id !== row.campaignId))
        }
    };

    const handleSort = (column, sortDirection) => {
        let field = ''
        if (column.name == 'Target Acos') {
            field = 'targetAcos'
        }
        else if (column.name == 'Target CPC') {
            field = 'targetCPC'
        }
        else if (column.name == 'Campaigns') {
            field = 'campaignName'
        }
        else if (column.name == 'Target CPC') {
            field = 'targetCPC'
        }
        else if (column.name == 'Bid Adjustment') {
            field = 'keywordBid'
        }
        else if (column.name == 'New Bid Adjustment') {
            field = 'newbidAdjustment'
        }
        else if (column.name == 'Impressions') {
            field = 'totalImpressions'
        }
        else if (column.name == 'Clicks') {
            field = 'totalClicks'
        }
        else if (column.name == 'CTR%') {
            field = 'totalCtr'
        }
        else if (column.name == 'Spend') {
            field = 'totalSpend'
        }
        else if (column.name == 'Sales') {
            field = 'totalSales'
        }
        else if (column.name == 'Orders') {
            field = 'totalOrders'
        }
        else if (column.name == 'Units') {
            field = 'totalOrders'
        }
        else if (column.name == 'CVR%') {
            field = 'totalCvr'
        } else if (column.name == 'ACOS%') {
            field = 'totalAcos'
        } else if (column.name == 'CPC') {
            field = 'totalCpc'
        } else if (column.name == 'ROAS') {
            field = 'totalRoas'
        }
        setFieldName(field)
        let sorting: sortType = sortEnum.ASC
        if (sortDirection !== 'asc') {
            sorting = sortEnum.DESC
        }
        setSortType(sorting)
        getCampaignData({ fieldName: field, sortType: sorting })
    };

    const handlePageChange = (page) => {
        setPageNo(page)
        getCampaignData({ pageNo: page, perPage: perPage });
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPageNo(page)
        setPerPage(newPerPage)
        getCampaignData({ perPage: newPerPage, pageNo: page });
        setExpandableCampaignIds([])
    };

    const ExpandedComponent = useCallback(({data}) =>
        <div className="sub_table campaign_table campaign_sub">
            <ExpandTable
                campaignName={data.campaignName}
                campaignId={String(data.campaignId)}
                keywordBid={data.keywordBid}
                placementData={data.placementsData || []}
                weekData={data.subDataTable || []}
                order={data.totalOrders}
                datePeriodType={data.datePeriodType}
                campaign={data}
            />
        </div>
    , [])

    return (
        <div className="data_table campaign_table scrollable_table">
            <DataTable
                key={perPage}
                columns={CampaignColumns()}
                data={campaigns}
                onSort={handleSort}
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                expandableIcon={{
                    collapsed: <AiOutlinePlus/>,
                    expanded: <AiOutlineMinus/>,
                }}
                onRowExpandToggled={handleRowExpandToggle}
                expandableRowExpanded={(row) => expandableCampaignIds.includes(row.campaignId)}
                pagination
                paginationTotalRows={campaignData.totalRecords}
                paginationPerPage={perPage}
                paginationRowsPerPageOptions={paginationRowsPerPageOptions()}
                paginationServer
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                keyField="campaignId"
                noDataComponent={!loaded ? <Empty /> : <div></div>}
            />
        </div>
    )
}

export default Table;
