import React from "react";
import {useActions} from "../../../hooks/useActions";
import {useHelperFunctions} from "../hooks/useHelperFunctions";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {dataRepresentationEnum} from "../../../types/customers";
import {Radio} from "antd";
import {CheckboxGroupProps} from "antd/es/checkbox";

const DataRepresentation = () => {
    const { setIsOrder } = useActions()
    const { setDataRepresentation } = useActions()
    const { expandableCampaignIds, dataRepresentationType} = useTypedSelector((select) => select.displaySettingsSp)

    const { childHandler } = useHelperFunctions()

    const options: CheckboxGroupProps<dataRepresentationEnum>['options'] = [
        { label: dataRepresentationEnum.PLACEMENT, value: dataRepresentationEnum.PLACEMENT },
        { label: dataRepresentationEnum.DATA_TABLE, value: dataRepresentationEnum.DATA_TABLE },
    ];

    const HandleRadio = (e) => {
        if (e.target.value === dataRepresentationEnum.DATA_TABLE) {
            setIsOrder(false)
        }
        setDataRepresentation(e.target.value)

        if (expandableCampaignIds?.length != 0) {
            childHandler(expandableCampaignIds, { name: e.target.value })
        }
    };

    return <Radio.Group
            className={"mx-2"}
            block
            options={options}
            defaultValue={dataRepresentationEnum.PLACEMENT}
            optionType="button"
            buttonStyle="solid"
            onChange={HandleRadio}
        />
}

export default DataRepresentation
