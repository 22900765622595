import React, {useEffect, useState} from "react";
import {UseFormReset} from "react-hook-form/dist/types/form";
import {formValue} from "../types/login";
import Cookies from "js-cookie";

interface RememberMeProps {
    formValue: formValue,
    setFormValue: (formValue: formValue) => void,
    reset: UseFormReset<formValue>
}

const RememberMe: React.FC<RememberMeProps> = ({formValue, setFormValue, reset}) => {
    const [isRememberMe, setIsRememberMe] = useState(false)

    useEffect(() => {
        const sellerLogin = Cookies.get('sellerLogin')
        if (sellerLogin) {
            setFormValue({
                email: JSON.parse(sellerLogin).email,
                password: JSON.parse(sellerLogin).password
            })
            reset({
                email: JSON.parse(sellerLogin).email,
                password: JSON.parse(sellerLogin).password
            })
            setIsRememberMe(true)
        }
    }, [])

    useEffect(() => {
        if (formValue && isRememberMe) {
            Cookies.set('sellerLogin', JSON.stringify(formValue))
        }
    }, [isRememberMe, formValue])

    const rememberMeHandler = (e) => {
        if (e.target.checked) {
            setIsRememberMe(true)
        }
        else {
            Cookies.remove('sellerLogin', JSON.stringify(formValue))
            setIsRememberMe(false)
        }
    }

    return <div className='remember'>
        <input
            type='checkbox'
            checked={isRememberMe}
            onClick={rememberMeHandler}
        />
        <label
            className='ps-2'>
            Remember me
        </label>
    </div>
}

export default RememberMe
