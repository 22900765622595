import $api from "../../axios";

const api = `/SB/target/`

const ApiHistory = (data) => {
    return $api.post(`${api}targetLogHistory`, data)
        .then(res => res.data).catch(res => res.data)
}
const ApiSBreverseBidChange = (data) => {
    return $api.post(`${api}reverseBidChange`, data)
        .then(res => res.data).catch(res => res.data)
}

export {
    ApiHistory,
    ApiSBreverseBidChange
}
