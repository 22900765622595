import React from "react";

const LoginButton: React.FC = () => {

    return <button
        type="submit"
        className="login-box-button"
    >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        Login
    </button>
}

export default LoginButton
