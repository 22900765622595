import {FilterAction, UserActionTypes} from "../types/filter";
import {Dispatch} from "redux";
import {getDefaultOrder} from "../../api-wrapper/setting/ApiSettings";

export const fetchDefaultOrder = (profileId: string | null) => {
    return async (dispatch: Dispatch<FilterAction>) => {
        try {
            dispatch({
                type: UserActionTypes.FETCH_DEFAULT_ORDER,
            })
            const defaultOrder = await getDefaultOrder({ profileId })
            dispatch({
                type: UserActionTypes.FETCH_DEFAULT_ORDER_SUCCESS,
                payload: defaultOrder.defaultOrder
            })
        }
        catch (e) {
            dispatch({
                type: UserActionTypes.FETCH_DEFAULT_ORDER_ERROR,
                payload: "Default orders load error"
            })
        }
    }
}

export const setDefaultOrder = (newOrder: number) => {
    return (dispatch: Dispatch<FilterAction>) => {
        dispatch({
            type: UserActionTypes.SET_DEFAULT_ORDER,
            payload: newOrder
        })
    }
}

export const setIsOrder = (isOrder: boolean) => {
    return (dispatch: Dispatch<FilterAction>) => {
        dispatch({
            type: UserActionTypes.SET_IS_ORDER,
            payload: isOrder
        })
    }
}

export const setDateRange = (from: string, to: string) => {
    return (dispatch: Dispatch<FilterAction>) => {
        dispatch({
            type: UserActionTypes.SET_DATE_RANGE,
            payload: {
                from,
                to,
            }
        })
    }
}
