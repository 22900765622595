import React from "react";
import {Dropdown, MenuProps, message} from "antd";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useActions} from "../../../hooks/useActions";
import {campaignsTypeEnum} from "../types/types";
import {ApiUnmange} from "../../../api-wrapper/campaign/ApiCampaignName";
import {useHelperFunctions} from "../hooks/useHelperFunctions";
import {ApiStore} from "../../../api-wrapper/advertising/ApiAdvertising";
import {campaignStates, stateByCampaignId} from "../../../types/customers";

enum options {
    "APPLY_BEST_CPC" = "Apply Best CPC",
    "UNMANAGE" = "Unmanage",
    "APPLY_ON_AMAZON" = "Apply On Amazon",
    "PAUSE" = "Pause",
    "ENABLE" = "Enable"
}

const CampaignActions: React.FC = () => {
    const { campaignData } = useTypedSelector((select) => select.sponsoredProduct)
    const { selectedCampaignIds } = useTypedSelector((select) => select.displaySettingsSp)
    const { setBestArr, setExpandableCampaignIds, setSelectedCampaignIds } = useActions()
    const { getCampaignData } = useHelperFunctions()
    const { fetchChangeCampaignsState } = useActions()

    const handleApplyBestCpc = () => {
        const findPlacement = campaignData.campaigns.filter(campaign =>
            selectedCampaignIds.includes(campaign.campaignId) && campaign?.placementsData
        )

        setBestArr(findPlacement.map(x => x.campaignId))
    }

    const HandleManageCampaign = () => {
        const profileId = localStorage.getItem("profileId");
        let data = {
            isManage: false,
            campaignIds: selectedCampaignIds,
            profileId: profileId,
            type: campaignsTypeEnum.SPONSORED_PRODUCTS
        };

        ApiUnmange(data)
            .then((status) => {
                if (status >= 200 && status < 300) {
                    message.success("Unmanage success")
                    getCampaignData()
                    return
                }

                message.error("Unmanage error")
            })
            .catch((err) => {
                message.error("Unmanage error")
            })
    }

    const handleSaveAmazon = () => {
        if (selectedCampaignIds.length === 0) {
            message.error('Select a company to update')
            return
        }

        const profileId = localStorage.getItem("profileId")
        let sendPlacement: any[] = []

        let filterPlacement = campaignData.campaigns.filter(campaign => selectedCampaignIds.includes(campaign.campaignId) && campaign.placementsData);
        const updatedIds: string[] = []
        filterPlacement.map(x => {
            const placementData = x.placementsData ? x.placementsData : []
            if (!updatedIds.includes(String(x.campaignId))) {
                updatedIds.push(String(x.campaignId))
                sendPlacement.push({
                    keywordBid: x.keywordBid || 0,
                    campaignName: x.campaignName,
                    campaignId: x.campaignId,
                    newKeywordBid: Number(x.newbidAdjustment),
                    TOS_Acos: placementData[0].targetAcos,
                    TOS_Cpc: placementData[0].targetCPC,
                    TOS_modifire: placementData[0]?.newbidAdjustment == undefined ? 0 : Number(placementData[0].newbidAdjustment),
                    ROS_Acos: placementData[1].targetAcos,
                    ROS_Cpc: placementData[1].targetCPC,
                    PP_Acos: placementData[2].targetAcos,
                    PP_Cpc: placementData[2].targetCPC,
                    PP_modifire: placementData[2]?.newbidAdjustment == undefined ? 0 : Number(placementData[2].newbidAdjustment),
                    TOS_tagetCPCAmazon: Number(placementData[0].tagetCPCAmazon),
                    PP_tagetCPCAmazon: Number(placementData[2].tagetCPCAmazon),
                    ROS_tagetCPCAmazon: Number(placementData[1].tagetCPCAmazon),
                    ROS_modifire: placementData[1]?.newbidAdjustment == undefined ? 0 : Number(placementData[1].newbidAdjustment),
                    old_TOS_modifire: placementData[0]?.placementModifier || 0,
                    old_ROS_modifire: placementData[1]?.placementModifier || 0,
                    old_PP_modifire: placementData[2]?.placementModifier || 0,
                })
            }
        })
        const data = {
            profileId: profileId,
            applyOn: "Amazon",
            timezone: localStorage.getItem('antonTimezone'),
            campaignData: sendPlacement
        }

        ApiStore(data)
            .then((status) => {
                if (status === 200) {
                    message.success("Saved success")
                    setSelectedCampaignIds([])
                    setExpandableCampaignIds([])
                    getCampaignData()
                } else {
                    message.error("Saved error")
                }
            })
            .catch((err) => {
                message.error("Saved error")
            })
    }

    function changeStateCampaign(state: campaignStates) {
        const statesByCampaignId: stateByCampaignId[] = selectedCampaignIds.map((campaignId) => {
            return {
                campaignId,
                state
            }
        })

        fetchChangeCampaignsState(statesByCampaignId)
        setSelectedCampaignIds([])
    }

    const handleMenuClick: MenuProps["onClick"] = (event) => {
        switch (event.key) {
            case options.APPLY_BEST_CPC:
                handleApplyBestCpc()
                break
            case options.UNMANAGE:
                HandleManageCampaign()
                break
            case options.APPLY_ON_AMAZON:
                handleSaveAmazon()
                break
            case options.PAUSE:
                changeStateCampaign(campaignStates.PAUSED)
                break
            case options.ENABLE:
                changeStateCampaign(campaignStates.ENABLED)
                break
        }
    }

    const items: MenuProps['items'] = [
        {
            label: options.APPLY_BEST_CPC,
            key: options.APPLY_BEST_CPC,
        },
        {
            label: options.UNMANAGE,
            key: options.UNMANAGE,
        },
        {
            label: options.APPLY_ON_AMAZON,
            key: options.APPLY_ON_AMAZON,
        },
        {
            label: options.PAUSE,
            key: options.PAUSE,
        },
        {
            label: options.ENABLE,
            key: options.ENABLE,
        },
    ];

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    return (
        <Dropdown.Button menu={menuProps}>
            Campaign Actions
        </Dropdown.Button>
    )
}

export default CampaignActions

