import React, {useEffect, useState} from "react";
import Table from "./Table";
import DisplaySettings from "./DisplaySettings";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useHelperFunctions} from "../hooks/useHelperFunctions";
import {useActions} from "../../../hooks/useActions";
import {Card, message, Spin} from "antd"
import {filters} from "../types/placement";
import {campaignStatesFilter, dataRepresentationEnum, matchTypesEnum} from "../../../types/customers";
import {getFilters} from "../../../api-wrapper/campaign/ApiFilter";
import {pageNameEnum} from "../../../types/page";
import style from "../styles/dataContent.module.scss"
import FullPageLoader from "../../../components/FullPageLoader/FullPageLoader";
import {LoadingOutlined} from "@ant-design/icons";
import CardLoader from "../../../components/CardLoader/CardLoader";

const DataContent: React.FC = () => {
    const { dateRange, isOrder} = useTypedSelector((select) => select.filter)
    const { error } = useTypedSelector((select) => select.sponsoredProduct)
    const { getCampaignData } = useHelperFunctions()
    const { setSelectedCampaignIds, setExpandableCampaignIds } = useActions()
    const { loaded } = useTypedSelector((select) => select.sponsoredProduct)

    const [ filters, setFilters ] = useState<filters>({
        campaignState: campaignStatesFilter.ALL,
        searchValue: "",
        matchType: matchTypesEnum.ALL,
        customFilters: [],
        dataRepresentationType: dataRepresentationEnum.PLACEMENT
    })
    useEffect( () => {
        getFilters(pageNameEnum.SPONSORED_PRODUCTS)
            .then(filtersData => {
                setFilters((filters) => {
                    const newFilters = { ...filters }
                    filtersData.forEach((filter) => {
                        newFilters[filter.type] = filter.value
                    })

                    return newFilters
                })
            })
            .catch((err) => {
                message.error('Error loading filters')
            })
    }, [])

    useEffect(() => {
        if (dateRange.from && dateRange.to) {
            getCampaignData()
        }
        setSelectedCampaignIds([])
        setExpandableCampaignIds([])
    }, [dateRange.from, dateRange.to, isOrder]);

    useEffect(() => {
        if (error) {
            message.error(error)
        }
    }, [error])

    return (
        <Card className={style.data_card}>
            <DisplaySettings
                filters={filters}
                setFilters={setFilters}
            />
            {
                loaded ?
                    <CardLoader/>
                    :
                    <Table
                        filters={filters}
                    />
            }
        </Card>
    );
};

export default DataContent;

