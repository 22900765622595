import {Dropdown} from "react-bootstrap";
import React from "react";
import {useNavigate} from "react-router-dom";

const ProfileSection = () => {
    let navigate = useNavigate();

    const logOutHandler = () => {
        localStorage.removeItem("sellerToken");
        localStorage.removeItem("profileId");
        localStorage.removeItem("fromDate");
        localStorage.removeItem("toDate");
        localStorage.removeItem("userName");
        localStorage.removeItem("radio");
        localStorage.removeItem("perPage");
        localStorage.removeItem("selectedItem");

        navigate("/login");
    };

    return (
        <div className="inner_filter d-lg-flex justify-content-xl-center ms-4">
            <div className="profile_section">
                <Dropdown>
                    <Dropdown.Toggle className="dropdown-btn" id="dropdow-basic">
                        <span className="me-2">
                            {localStorage.getItem("userName") || "Username"}
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => logOutHandler()}>
                            LogOut
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    )
}

export default ProfileSection;
