import { FilterAction, FilterState, UserActionTypes } from "../types/filter";
import {getLastMonth} from "../../helper/date.helper";

const initialState: FilterState = {
    dateRange: {
        ...getLastMonth()
    },
    isOrder: false,
    defaultOrder: 0,
    loaded: false,
    error: null
}

export const filterReducer = (state = initialState, action: FilterAction): FilterState => {
    switch (action.type) {
        case UserActionTypes.FETCH_DEFAULT_ORDER:
            return {
                ...state,
                loaded: true,
            }
        case UserActionTypes.FETCH_DEFAULT_ORDER_SUCCESS:
            return {
                ...state,
                loaded: false,
                defaultOrder: action.payload,
            }
        case UserActionTypes.FETCH_DEFAULT_ORDER_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        case UserActionTypes.SET_DEFAULT_ORDER:
            return {
                ...state,
                defaultOrder: action.payload
            }
        case UserActionTypes.SET_IS_ORDER:
            return {
                ...state,
                isOrder: action.payload
            }
        case UserActionTypes.SET_DATE_RANGE:
            return {
                ...state,
                dateRange: action.payload
            }
        default:
            return state
    }
}
