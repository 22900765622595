import DateRange from "./DateRange";
import CheckFilterType from "./CheckFilterType";
import Order from "./Order";
import ProfileSection from "./ProfileSection";

const Filters = ({ showOrder, showDate }) => {

    return (
        <div className="filter_section">
            {
                showDate &&
                <DateRange/>
            }
            {
                showOrder &&
                <div className="inner_filter d-flex justify-content-xl-center align-items-center">
                    <CheckFilterType/>
                    <Order/>
                </div>
            }

            <ProfileSection/>
        </div>
    )
}

export default Filters;
