import {useState} from "react";
import {useHelperFunctions} from "../modules/sponsoredProducts/hooks/useHelperFunctions";
import {useActions} from "../hooks/useActions";
import {useTypedSelector} from "../hooks/useTypedSelector";
import {Button} from "antd";

const ExpendAllButton = () => {
    const [isExpand, setIsExpand] = useState<boolean>(true)
    const { setSelectedCampaignIds, setExpandableCampaignIds} = useActions()
    const { campaignData } = useTypedSelector((select) => select.sponsoredProduct)
    const { childHandler } = useHelperFunctions()

    const handleExpandAllClick = () => {
        if (isExpand) {
            setIsExpand(false)

            const allRowIds = campaignData.campaigns.map((row) => row.campaignId);
            setExpandableCampaignIds(allRowIds)
            childHandler(allRowIds)
        } else {
            setIsExpand(true)
            setSelectedCampaignIds([])
            setExpandableCampaignIds([])
        }
    };

    return <div>
        <Button onClick={handleExpandAllClick}>
            {isExpand ? "Expand All" : "Collapse All"}
        </Button>
    </div>
}

export default ExpendAllButton
