import React, {useState} from "react";
import {filters} from "../types/placement";
import {setStateType} from "../types/setStateType";
import RtSearchInput from "../../../components/RtSearchInput/RtSearchInput";

interface SearchInputProps {
    setFilters: setStateType<filters>
}

const SearchInput: React.FC<SearchInputProps> = ({setFilters}) => {
    const [searchValue, setSearchValue] = useState<string>("")

    function onChange(event: React.ChangeEvent<HTMLInputElement>) {
        const searchValue = event.target.value

        setSearchValue(searchValue)
        setFilters((filter) => {
            return {
                ...filter,
                searchValue: searchValue
            }
        })
    }

    return <RtSearchInput
            placeholder="Search Campaign"
            value={searchValue}
            onChange={onChange}
        />
}

export default SearchInput
