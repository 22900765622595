import React from "react"
import styles from "./loader.module.scss"
import { Spin} from "antd"
import { LoadingOutlined } from "@ant-design/icons"

interface LoaderProps {
    variant?: 'white'
}

const Loader: React.FC<LoaderProps> = ({variant}) => {

    return <Spin
            indicator={
                <LoadingOutlined
                    className={variant === 'white' ? styles.spin_white : ''}
                    spin
                />
            }
        />
}

export default Loader
