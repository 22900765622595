import React, {useEffect, useState} from "react";
import {useHelperFunctions} from "../hooks/useHelperFunctions";
import {useActions} from "../../../hooks/useActions";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {ApiCustomFilter} from "../api/filterApi";
import RtMultipleSelect from "../../../ui/RtMultipleSelect";
import {Button, Space} from "antd";

type storeArr = {
    label: string,
    value: string
}

const SelectForm = () => {
    const [optionName, setOptionName] = useState<storeArr[]>([])
    const { setSelectedOptions, setExpandableCampaignIds, setSelectedCampaignIds } = useActions()
    const { selectedOptions } = useTypedSelector((select) => select.displaySettingsSp)

    const {getCampaignData} = useHelperFunctions()

    const handleSelectAllFilter = (selectedList) => {
        setSelectedOptions(selectedList)
    };

    const getFilterData = async () => {
        const profileId = localStorage.getItem("profileId");
        let storeArr: storeArr[] = [];
        let data = {
            profileId: profileId,
        };

        try {
            const customFilter = await ApiCustomFilter(data)
            customFilter.data.forEach((el) => {
                storeArr.push({
                    value: el._id,
                    label: el.filterName,
                })
            })
            setOptionName(storeArr)
        } catch (error) {
            console.error("Something went wrong");
        }
    };

    const filterApply = () => {
        setExpandableCampaignIds([]);
        setSelectedCampaignIds([]);
        localStorage.removeItem('ids')
        localStorage.removeItem("SPtarget");

        setSelectedOptions(selectedOptions)
        getCampaignData();
    };

    useEffect(() => {
        getFilterData()
    }, [])

    return <Space.Compact className={"mx-1"}>
            <RtMultipleSelect
                onChange={handleSelectAllFilter}
                options={optionName}
                placeholder={"Custom settings"}
            />
            <Button onClick={() => filterApply()}>
                Apply
            </Button>
        </Space.Compact>
}

export default SelectForm
