import {SponsoredProductAction, SponsoredProductActionType, SponsoredProductState} from "../types/sponsoredProducts";

const initialState: SponsoredProductState = {
    campaignData: {
        campaigns: [],
        defaultTargetAcos: [],
        limitedCPCValue: {
            conditions: []
        },
        targetAcos: [],
        getAOVData: [],
        TOSPercentage: 0,
        currentPageNo: 0,
        defaultBid: 0,
        defaultOrder: 0,
        limitValue: 0,
        maxTargetCPC: 0,
        minTargetCPC: 0,
        totalPages: 0,
        totalRecords: 0,
    },
    loaded: false,
    error: null,
}

export const sponsoredProductReducer = (state = initialState, action: SponsoredProductAction): SponsoredProductState => {
    switch (action.type) {
        case SponsoredProductActionType.FETCH_SPONSORED_PRODUCTS:
            return {
                ...state,
                loaded: true,
                error: null,
            }
        case SponsoredProductActionType.FETCH_SPONSORED_PRODUCTS_SUCCESS:
            return {
                campaignData: action.payload,
                loaded: false,
                error: null,
            }
        case SponsoredProductActionType.FETCH_SPONSORED_PRODUCTS_ERROR:
            return {
                ...state,
                loaded: false,
                error: action.payload,
            }
        case SponsoredProductActionType.SET_CAMPAIGN_DATA:
            return {
                ...state,
                campaignData: {
                    ...state.campaignData,
                    campaigns: action.payload
                }
            }
        case SponsoredProductActionType.CHANGE_CAMPAIGN:
            return {
                ...state,
                campaignData: {
                    ...state.campaignData,
                    campaigns: state.campaignData.campaigns.map((campaign) => {
                        if (campaign.campaignId === action.payload.campaignId) {
                            return action.payload
                        }
                        return campaign
                    })
                }
            }
        case SponsoredProductActionType.FETCH_CAMPAIGN_STATE_SUCCESS:
            return {
                ...state,
                campaignData: {
                    ...state.campaignData,
                    campaigns: state.campaignData.campaigns.map((campaign) => {
                        const campaignIdWithChangedState = action.payload.find((stateByCampaignId) =>
                            stateByCampaignId.campaignId === campaign.campaignId
                        )

                        if (campaignIdWithChangedState) {
                            return {
                                ...campaign,
                                campaignStatus: campaignIdWithChangedState.state
                            }
                        }
                        return campaign
                    })
                }
            }
        case SponsoredProductActionType.FETCH_CAMPAIGN_STATE_ERROR:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state
    }
}
