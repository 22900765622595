import {bindActionCreators} from "redux";
import {useDispatch} from "react-redux";
import * as FilterActionCreators from "../redux/actionCreators/filter";
import {
    DisplaySettingsActionCreators,
    SponsoredProductActionCreators,
} from "../modules/sponsoredProducts";

export const useActions = () => {
    const dispatch = useDispatch()
    return bindActionCreators({
        ...FilterActionCreators,
        ...SponsoredProductActionCreators,
        ...DisplaySettingsActionCreators,
    },
    dispatch)
}
