import {DateRangePicker} from "rsuite";
import React, {useEffect, useState} from "react";
import moment from "moment-timezone";
import {useActions} from "../../../hooks/useActions";
import {useTypedSelector} from "../../../hooks/useTypedSelector";

const DateRange = () => {
    const { setDateRange } = useActions()
    const { dateRange} = useTypedSelector((select) => select.filter)

    const [dateRangeForDisplay, setDateRangeForDisplay] = useState<[Date, Date]>()
    const dateChangeHandler = (newDate) => {
        const from = newDate[0]
        const to = newDate[1]

        setDateRange(
            moment(from).format("YYYY-MM-DD"),
            moment(to).format("YYYY-MM-DD")
        )
    };

    useEffect(() => {
        const from = dateRange.from ? moment(dateRange.from, "YYYY-MM-DD").toDate() : new Date()
        const to = dateRange.to ? moment(dateRange.to, "YYYY-MM-DD").toDate() : new Date()
        setDateRangeForDisplay([from, to])
    }, [dateRange.from, dateRange.to])

    return (
        <div className="inner_filer date_range me-0 me-md-4 mb-3 mb-md-0">
            {
                <DateRangePicker
                    className="filter_date"
                    placeholder="Select Date"
                    onChange={(e) => dateChangeHandler(e)}
                    format="yyyy-MM-dd"
                    value={dateRangeForDisplay}
                    placement="bottomEnd"
                />
            }
        </div>
    )
}

export default DateRange;
