import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useActions} from "../../../hooks/useActions";
import React from "react";
import SwitchCampaignState from "./SwitchCampaignState";
import ProductPosition from "./ProductPosition";

const CampaignColumns = (): any[] => {
    const { selectedCampaignIds } = useTypedSelector((select) => select.displaySettingsSp)
    const { campaignData } = useTypedSelector((select) => select.sponsoredProduct)

    const { setSelectedCampaignIds } = useActions()

    const selectCampaign = (e, rowId) => {
        const isChecked = e.target.checked;

        if (isChecked) {
            setSelectedCampaignIds([...selectedCampaignIds, rowId])
        } else {
            const updatedSelectedRows = selectedCampaignIds.filter((id) => id !== rowId);
            setSelectedCampaignIds(updatedSelectedRows);
        }
    };

    const selectAllCampaigns = (e) => {
        const isChecked = e.target.checked

        if (isChecked) {
            const campaignIds = campaignData.campaigns.map((campaign) => campaign.campaignId)
            setSelectedCampaignIds(campaignIds)
        } else {
            setSelectedCampaignIds([]);
        }
    }

    return [
            {
                name: (
                    <input
                        type="checkbox"
                        onClick={(e) => selectAllCampaigns(e)}
                    />
                ),
                width: "50px",
                cell: (row) => (
                    <input
                        type="checkbox"
                        onChange={(e) => selectCampaign(e, row.campaignId)}
                        checked={selectedCampaignIds.includes(row.campaignId)}
                    />
                ),
            },
            {
                name: "Organic position",
                cell: (row) => <ProductPosition position={row.organicPosition} asins={row.asins} campaignName={row.campaignName}/>,
            },
            {
                name: "Sponsored positions",
                cell: (row) => <ProductPosition position={row.sponsoredPosition} asins={row.asins} campaignName={row.campaignName}/>,
            },
            {
                name: "Target Acos",
                cell: (row) => "-",
            },
            {
                name: "Active" ,
                cell: (row) => <SwitchCampaignState campaign={row}/>
            },
            {
                name: "Campaigns",
                sortable: true,
                selector: (row) => row.campaignName,
                cell: (row) => {
                    return (
                        <div>{row.campaignName}</div>
                    )
                },
            },
            {
                name: "Bid Adjustment",
                sortable: true,
                width: "80px",
                selector: (row) => row.keywordBid,
                cell: (row) => `$${row.keywordBid ? row.keywordBid : '0'}`,
            },
            {
                name: "Target CPC In Amazon",
                sortable: true,
                width: "80px",
                selector: (row) => row.tagetCPCAmazon,
                cell: (row) => (row.tagetCPCAmazon ? row.tagetCPCAmazon : "-")
            },
            {
                name: "Target CPC",
                sortable: true,
                selector: (row) => row.targetCPC,
                cell: (row) => row.targetCPC !== undefined ? row.targetCPC.toString() : "-",
            },
            {
                name: "Best CPC",
                sortable: true,
                selector: (row) => row.bestCPC,
                cell: (row) => row.bestCPC !== undefined ? row.bestCPC.toString() : "-",
            },
            {
                name: "Limited CPC",
                sortable: true,
                selector: (row) => row.limitedCPC,
                cell: (row) => row.limitedCPC !== undefined ? row.limitedCPC.toString() : "-",
            },
            {
                name: "New Bid Adjustment",
                sortable: true,
                selector: (row) => row.newKeywordBid,
                cell: (row) =>
                    row.newKeywordBid || row.newKeywordBid === 0 ? `$${row.newKeywordBid.toFixed(2)}` : "-",
            },
            {
                name: "Impressions",
                selector: (row) => row.totalImpressions,
                sortable: true,
            },
            {
                name: "Clicks",
                selector: (row) => row.totalClicks,
                sortable: true,
            },
            {
                name: "CTR%",
                sortable: true,
                selector: (row) => row.totalCtr,
                cell: (row) => `${row.totalCtr || 0}%`,
            },
            {
                name: "Spend",
                sortable: true,
                selector: (row) => row.totalSpend,
                cell: (row) => `$${row.totalSpend}`,
            },
            {
                name: "Sales",
                sortable: true,
                selector: (row) => row.totalSales,
                cell: (row) => `$${row.totalSales}`,
            },
            {
                name: "Orders",
                selector: (row) => row.totalOrders,
                sortable: true,
            },
            {
                name: "Units",
                selector: (row) => row.totalUnits,
                sortable: true,
            },
            {
                name: "CVR%",
                sortable: true,
                selector: (row) => row.totalCvr,
                cell: (row) => `${row.totalCvr}%`,
            },
            {
                name: "ACOS%",
                sortable: true,
                selector: (row) => row.totalAcos,
                cell: (row) => `${row.totalAcos}%`,
            },
            {
                name: "CPC",
                sortable: true,
                selector: (row) => row.totalCpc,
                cell: (row) => `$${row.totalCpc}`,
            },
            {
                name: "ROAS",
                selector: (row) => row.totalRoas,
                sortable: true,
            }
        ]
}

export default CampaignColumns;
