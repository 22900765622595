
export interface IDefaultOrder {
    defaultOrder: number
}

export enum loadDataFilterEnum {
    ORDERS = "Orders",
    DATE = "Date"
}

export type loadDataFilter = loadDataFilterEnum
