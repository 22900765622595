import $api from "../../axios";
import {getFilterBody, saveFilterBody} from "./types";
import {pageNameEnum} from "../../types/page";
import {customerFilters} from "../../types/customers";

const api = `/filter/`

const ApiCreate = (data) => {
    return $api.post(`${api}createFilter`, data)
        .then(res => res.data).catch(res => res.data)
}
const ApiRuleConditions = (id) => {
    return $api.get(`${api}getFilter/${id}`)
        .then(res => res.data).catch(res => res.data)
}
const ApiupdateRule = (id, data) => {
    return $api.post(`${api}updateFilter/${id}`, data)
        .then(res => res.data).catch(res => res.data)
}

const ApideleteRule = (id) => {
    return $api.delete(`${api}deleteFilter/${id}`)
        .then(res => res.data).catch(res => res.data)
}

const saveFilter = async (body: saveFilterBody) => {
    return $api.post(`${api}saveFilter`, body)
}

const getFilters = async (pageName: pageNameEnum, type?: customerFilters) => {
    let query = `pageName=${pageName}`
    if (type) {
        query += `&type=${type}`
    }

    return $api.get(`${api}getFilters?${query}`)
        .then((res) => res.data)
}

export {
    ApiCreate,
    ApiRuleConditions,
    ApiupdateRule,
    ApideleteRule,
    saveFilter,
    getFilters
}

