import {AxiosResponse} from "axios";
import {ICustomFilter} from "../types/customFilter";
import $api from "../../../axios";

const filterApi = `/filter/`

export const ApiCustomFilter = (data) => {
    return $api.post<ICustomFilter, AxiosResponse<ICustomFilter>>(`${filterApi}getAllFilterWithOutPagination`, data)
        .then(res => res.data).catch(res => res.data)
}
