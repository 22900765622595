
export const CAMPAIGN_STATES = {
    "ENABLED": "ENABLED",
    "PAUSED": "PAUSED",
    "ARCHIVED": "ARCHIVED"
}

export const CAMPAIGN_STATES_FILTER = {
    ALL: "All",
    ENABLED: 'Enabled',
    PAUSED: 'Paused',
    ARCHIVED: 'Archived',
    ALL_BUT_ARCHIVE: 'All but archive'
}

export const CAMPAIGN_STATES_FILTER_ENUM = [
    CAMPAIGN_STATES_FILTER.ALL,
    CAMPAIGN_STATES_FILTER.ENABLED,
    CAMPAIGN_STATES_FILTER.ARCHIVED,
    CAMPAIGN_STATES_FILTER.PAUSED,
    CAMPAIGN_STATES_FILTER.ALL_BUT_ARCHIVE
]
