import React, {useRef, useState} from "react";
import {message, Switch} from "antd";
import {CAMPAIGN_STATES} from "../constants/campaign.constants";

const SwitchCampaignState = ({campaign, campaignData, setCampaignData, changeCampaignState}) => {
    const [ isActive, setIsActive ] = useState(campaign.campaignStatus === CAMPAIGN_STATES.ENABLED)
    const prevIsActive = useRef(isActive)

    async function onChange(checked) {
        try {
            setIsActive(checked)
            if (checked) {
                await changeCampaignState({
                    stateByCampaignIds: [{
                        campaignId: campaign.campaignId,
                        state: CAMPAIGN_STATES.ENABLED
                    }]
                })
                changeCampaignData(CAMPAIGN_STATES.ENABLED)
                return
            }

            await changeCampaignState({
                stateByCampaignIds: [{
                    campaignId: campaign.campaignId,
                    state: CAMPAIGN_STATES.PAUSED
                }]
            })
            changeCampaignData(CAMPAIGN_STATES.PAUSED)
        }
        catch (e) {
            setIsActive(prevIsActive.current)
            message.error("Unable to change the status.")
        }
    }

    function changeCampaignData (campaignStatus) {
        setCampaignData(
            campaignData.map((prevCampaign) => {
                if (prevCampaign.campaignId === campaign.campaignId) {
                    return {
                        ...prevCampaign,
                        status: campaignStatus
                    }
                }

                return prevCampaign
            })
        )
    }

    return (
        <Switch
            checked={isActive}
            onChange={onChange}
            size='small'
        />
    )
}

export default SwitchCampaignState
